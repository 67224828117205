import fetchApi from '../../services/fetch';
import { niAPI } from '../../constants/api';
import { updateGlobalTracker, track } from 'utils/segmenTracker';
import { ACTIVATION, CAPTURE_ELEMENT_FRNTID, CAPTURE_ELEMENT_REAR, PHOTO_CAPTURE_COMPLETED, PHOTO_CAPTURE_FAILED } from 'constants/segmentEvents';
import commonSegmentCalls from 'utils/segmentTracker/NI/commonSegmentCalls';

const initialState = {
  validation: {},
  data: { fileName: '' },
};

export const idDetails = {
  state: { ...initialState },
  reducers: {
    updateIdDetails(state, payload) {
      return { ...state, data: { ...state.data, ...payload } };
    },
    clearIdDetails() {
      return { ...initialState };
    },
  },
  effects: (dispatch) => {
    return {
      async uploadFront({ data }) {
        dispatch.apiStatus.setProcessing(true);
        this.clearIdDetails();
        return fetchApi({
          method: 'post',
          url: niAPI.upload,
          data,
        })
          .then((response) => {
            const {
              data: {
                Payload: { fileName, imageUrl },
              },
            } = response;
            const repoLink = fileName?.substring(0, fileName.lastIndexOf('/'));
            this.updateIdDetails({
              fileName: repoLink,
              frontIDFileName: fileName,
              frontIDImageUrl: imageUrl,
            });
            updateGlobalTracker({ photo_capture_front: true })
            track(PHOTO_CAPTURE_COMPLETED, { journey: ACTIVATION, capture_element: CAPTURE_ELEMENT_FRNTID})
            dispatch.apiStatus.setProcessing(false);
            return Promise.resolve({ success: true, repoLink });
          })
          .catch((error) => {
            updateGlobalTracker({ photo_capture_front: false });
            track(PHOTO_CAPTURE_FAILED, { journey: ACTIVATION, capture_element: CAPTURE_ELEMENT_FRNTID, error_type: 'error', error_id: error.errorCode, error_message: error.message});
            commonSegmentCalls.onApiFail(error, niAPI.upload, 'Front ID Upload API');
            dispatch.apiStatus.setRequestFailed({ ...error });
            return Promise.resolve({ success: false });
          });
      },

      uploadRear({ data }) {
        dispatch.apiStatus.setProcessing(true);
        return fetchApi({
          method: 'post',
          url: niAPI.upload,
          data: data,
        })
          .then((response) => {
            const {
              data: {
                Payload: { fileName, imageUrl },
              },
            } = response;
            this.updateIdDetails({
              rearIDFileName: fileName,
              rearIDImageUrl: imageUrl,
            });
            updateGlobalTracker({ photo_capture_rear: true })
            track(PHOTO_CAPTURE_COMPLETED, { journey: ACTIVATION, capture_element: CAPTURE_ELEMENT_REAR})
            dispatch.apiStatus.setProcessing(false);
            return Promise.resolve({ success: true });
          })
          .catch((error) => {
            updateGlobalTracker({ photo_capture_rear: false });
            track(PHOTO_CAPTURE_FAILED, { journey: ACTIVATION, capture_element: CAPTURE_ELEMENT_REAR, error_type: 'error', error_id: error.errorCode, error_message: error.message});
            commonSegmentCalls.onApiFail(error, niAPI.upload, 'Rear ID Upload API');
            dispatch.apiStatus.setRequestFailed({ ...error });
            return Promise.resolve({ success: false });
          });
      },
    };
  },
};
