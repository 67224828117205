import { niAPI } from 'constants/api';
import { ACTIVATION, ORDER_FAILED } from 'constants/segmentEvents';
import fetchApi from 'services/fetch';
import { getUpdateParamsFunc } from 'utils/leadParams/ni';
import { track, updateGlobalTracker } from 'utils/segmenTracker';
import commonSegmentCalls from 'utils/segmentTracker/NI/commonSegmentCalls';

const initialState = {
    clientDetails: {},
    orderStatus: {},
    finalStatus: {},
    basket: {},
    response: {},
    responseDAR: {},
    selectedClientType: {},
};
  
export const activation = {
    state: { ...initialState },
    reducers: {
        updateClientInformation(state, payload) {
            return {
                ...state,
                clientDetails: { ...state.clientDetails, ...payload },
            };
        },
        updateOrderStatus(state, payload) {
            return {
                ...state,
                orderStatus: { ...state.orderStatus, ...payload },
            };
        },
        updatefinalStatus(state, payload) {
            return {
                ...state,
                finalStatus: { ...state.finalStatus, ...payload },
            };
        },
        resetActivationStatus(state) {
            return {
                ...state,
                finalStatus: {},
                orderStatus: {},
            };
        },
        updateBasket(state, payload) {
            return {
                ...state,
                basket: payload,
            };
        },
        clearActivation() {
            return { ...initialState };
        },
        updateResponse(state, payload) {
            return {
                ...state,
                response: payload,
            };
        },
        updateResponseDAR(state, payload) {
            return {
                ...state,
                responseDAR: payload,
            };
        },
        updateSelectedClientType(state, payload) {
            return {
              ...state,
              selectedClientType: { ...payload },
            };
        },
    },
    effects: (dispatch) => {
        return {
            async createLead(params) {
                dispatch.apiStatus.setProcessing(true);
                dispatch.documentUpload.clearDocumetDetails();
                return fetchApi({
                    method: 'POST',
                    url: niAPI.createLead,
                    data: { ...params },
                })
                .then((response) => {
                    this.updateBasket(response.data);
                    updateGlobalTracker({ lead_id: response?.data?.response?.id || '' });
                    dispatch.idDetails.clearIdDetails();
                    dispatch.apiStatus.setProcessing(false);
                    return Promise.resolve({success:true});
                })
                .catch((err) => {
                    dispatch.apiStatus.setRequestFailed({ ...err });
                    commonSegmentCalls.onApiFail(err,niAPI.createLead,'Create Lead API');
                    return Promise.resolve({success:false});
                });
            },
            async updateLead({ type, ...params }, state) {
                const updateFunc = getUpdateParamsFunc(type || '');
                dispatch.apiStatus.setProcessing(true);
                return fetchApi({
                    method: 'PUT',
                    url: niAPI.updateLead.replace(
                        ':id',
                        state?.activation?.basket?.response?.id
                    ),
                    data: { ...updateFunc({...params, contractId: state.documentUpload?.data?.contract?.contractId }) },
                })
                .then(() => {
                    dispatch.apiStatus.setProcessing(false);
                    return Promise.resolve({success:true});
                })
                .catch((err) => {
                    let errObj = { ...err };
                    if(type === 'customerLocation'){
                        const errMsg = err?.rawResponse?.errors?.[0]?.message || err?.rawResponse?.errors?.message;
                        if(errMsg) errObj.message = errMsg;
                    }
                    dispatch.apiStatus.setRequestFailed(errObj)
                    commonSegmentCalls.onApiFail(err,niAPI.updateLead.replace(':id',state?.activation?.basket?.response?.id),'Update Lead API');
                    return Promise.resolve({success:false});
                });
            },
            async submitLead({ ...params }, state) {
                const updateFunc =  ({pha_data, fileName}) => {
                    return {
                        "existingFolderPath": fileName,
                        "activateService": {
                            "createBasketAndAddItemsMutation": {
                              "basketCreateMutation": {
                                "included": {
                                  "newInstance": {
                                    "attributes": {
                                      "leadStep": "commit_basket",
                                      "salesContext": {
                                        "salespersonCode": `${pha_data?.user}`
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                    }
                };
                dispatch.apiStatus.setProcessing(true);
                return fetchApi({
                    method: 'POST',
                    url: niAPI.updateLead.replace(
                        ':id',
                        state?.activation?.basket?.response?.id
                    ),
                    data: { ...updateFunc(params) },
                })
                .then((response) => {
                    this.updateResponse(response.data);
                    dispatch.apiStatus.setProcessing(false);
                    return Promise.resolve({success:true, data: response.data?.response});
                })
                .catch((err) => {
                    let errObj = { ...err };
                    const errMsg = err?.rawResponse?.errors?.[0]?.message || err?.rawResponse?.errors?.message;
                    if(errMsg) errObj.message = errMsg;
                    dispatch.apiStatus.setRequestFailed(errObj)
                    track(ORDER_FAILED, { journey: ACTIVATION, error_type: 'error', error_id: err.errorCode, error_message: err.message });
                    commonSegmentCalls.onApiFail(err,niAPI.updateLead.replace(':id',state?.activation?.basket?.response?.id),'Submit Lead API');
                    return Promise.resolve({success:false});
                });
            },
            async updateDAR({ ...params }, state){
                dispatch.apiStatus.setProcessing(true);
                return fetchApi({
                    method: 'POST',
                    url: niAPI.updateDAR,
                    data: params,
                })
                .then((response) => {
                    this.updateResponseDAR(response.data);
                    dispatch.apiStatus.setProcessing(false);
                    return Promise.resolve({success:true});
                })
                .catch((err) => {
                    dispatch.apiStatus.setRequestFailed({ ...err })
                    commonSegmentCalls.onApiFail(err,niAPI.updateDAR,'Update DAR API');
                    return Promise.resolve({success:false});
                });
            },
            async sendEmailConfirmation({ ...params }, state){
                dispatch.apiStatus.setProcessing(true);
                return fetchApi({
                    method: 'POST',
                    url: niAPI.emailConfirmation,
                    data: params,
                })
                .then(() => {
                    dispatch.apiStatus.setProcessing(false);
                    return Promise.resolve({success:true});
                })
                .catch((err) => {
                    dispatch.apiStatus.setRequestFailed({ ...err })
                    commonSegmentCalls.onApiFail(err,niAPI.updateDAR,'Email Confirmation API');
                    return Promise.resolve({success:false});
                });
            },
            async invoiceInfo({ id, email }){
                dispatch.apiStatus.setProcessing(true);
                return fetchApi({
                    method: 'POST',
                    url: niAPI.invoice.replace(':id', id),
                    data: { email },
                })
                .then((res) => {
                    dispatch.apiStatus.setProcessing(false);
                    if(res?.data?.code === "4013"){
                        commonSegmentCalls.onApiFail({message: res?.data?.message, errorCode: res?.data?.code, ...res}, niAPI.invoice.replace(':id', id), 'EcoFactura API');
                    }
                    return Promise.resolve({success:true});
                })
                .catch((err) => {
                    dispatch.apiStatus.setProcessing(false);
                    commonSegmentCalls.onApiFail(err,niAPI.invoice.replace(':id', id),'EcoFactura API');
                    return Promise.resolve({success:false});
                });
            }
        };
    },
};


