import { niAPI } from 'constants/api';
import fetchApi from 'services/fetch';
import { isEmpty } from 'lodash';
import { removeWhiteSpace } from 'utils/utils';
import commonSegmentCalls from 'utils/segmentTracker/NI/commonSegmentCalls';

const initialState = {
  isExisting: false,
  clientInfo: {},
  debts: [],
  selectedAddressData: {},
  debtsChecked: false,
  clientTypes: [],
};

const isHomeDebt = (val) => val?.type === 'home' && val?.dueInvoicesCount >= 2 && val?.dueAmount > 0;

const isMobileDebt = (val) =>
  val?.type === 'mobile' &&
  val?.dueAmount > 10 &&
  new Date(val?.dueDate)?.getTime() < Date.now() &&
  ['unpaid', 'overdue'].includes(val?.status);

const getDocID = (id = '') => {
  switch (id.toLowerCase()) {
    case 'ci':
      return 'cid';
    case 'ruc':
      return 'ruc';
    case 'pas':
      return 'pp';
    default:
      return id;
  }
};

export const clientInformation = {
  state: { ...initialState },
  reducers: {
    updateClientInfo(state, payload) {
      return { ...state, ...payload };
    },
    updateIsExisting(state, payload) {
      return {
        ...state,
        isExisting: payload,
      };
    },
    updateAgreementInformation(state, payload) {
      return {
        ...state,
        clientInfo: { ...state.clientInfo, ...payload },
      };
    },
    updateSelectedAddress(state, payload) {
      return {
        ...state,
        selectedAddressData: { ...payload },
      };
    },
    updateDebtInformation(state, payload) {
      return {
        ...state,
        debts: [...state.debts, ...payload],
      };
    },
    clearCurrentInfo() {
      return {
        ...initialState,
      };
    },
    updateDebtCheck(state, payload) {
      return { ...state, debtsChecked: payload };
    },
    updateClientTypes(state, payload) {
      return {
        ...state,
        clientTypes: [ ...payload ],
      };
    },
  },
  effects: (dispatch) => {
    return {
      async getClientInformation(params) {
        dispatch.apiStatus.setProcessing(true);
        this.clearCurrentInfo();
        const id = removeWhiteSpace(params?.inputDocumentNumber || '');
        return fetchApi({
          method: 'get',
          url: niAPI.getClientsById.replace(':id', id)?.replace(':docID', getDocID(params.documentType)),
        })
          .then((response) => {
            if (isEmpty(response?.data)) {
              dispatch.apiStatus.setProcessing(false);
              return Promise.resolve({ success: true, existingService: false, debt: false });
            }
            this.updateAgreementInformation(response.data);
            const accountList = response?.data?.customerAccountList?.reduce((list, item) => {
              if (item?.accountList?.length > 0) {
                list = [...list, ...item.accountList];
              }
              return list;
            }, []);
            if (accountList.length) {
              const isHomeCRM = accountList.find((x) => x.sourceSystemId === 'SIGA');
              const promise = accountList.map((info) => {
                return this.getClientDebts({
                  id: info?.billingAccountId,
                  type: info?.sourceSystemId,
                });
              });
              return Promise.allSettled(promise)
                .then((response) => {
                  dispatch.apiStatus.setProcessing(false);
                  this.updateDebtCheck(true);
                  const filteredResponse = response
                    .filter((res) => res.value)
                    .map((res) => res.value)
                    .filter((val) => isHomeDebt(val) || isMobileDebt(val));
                  if (filteredResponse.length) {
                    this.updateDebtInformation(filteredResponse);
                    return Promise.resolve({ success: true, existingService: true, debt: true });
                  }
                  return Promise.resolve({ success: true, existingService: !!isHomeCRM, debt: false });
                })
                .catch(() => {
                  this.updateDebtCheck(true);
                  dispatch.apiStatus.setProcessing(false);
                  return Promise.resolve({ success: true, existingService: !!isHomeCRM, debt: false });
                });
            } else {
              dispatch.apiStatus.setProcessing(false);
              return Promise.resolve({ success: true, existingService: false, debt: false });
            }
          })
          .catch((error) => {
            if (error?.errorCode === 404) {
              dispatch.apiStatus.setProcessing(false);
              return Promise.resolve({ success: true, existingService: false, debt: false });
            } else {
              commonSegmentCalls.onApiFail(error, niAPI.getClientsById.replace(':id', id), 'Get client details API');
              dispatch.apiStatus.setRequestFailed({ ...error });
              return Promise.resolve({ success: false });
            }
          });
      },
      async getClientDebts({ id, type }) {
        const url = type === 'SIGA' ? niAPI.getClientHomeDebts : niAPI.getClientMobileDebts;
        return fetchApi({
          method: 'get',
          url: url.replace(':id', id),
        })
          .then((response) => {
            return { ...response.data, numero: id, type: type === 'SIGA' ? 'home' : 'mobile' };
          })
          .catch((err) => {
            commonSegmentCalls.onApiFail(err, url.replace(':id', id), 'Check debt API');
          });
      },
      async getClientType() {
        dispatch.apiStatus.setProcessing(true);
        return fetchApi({
          method: "GET",
          url: niAPI.clientType
        })
        .then(response => {
          this.updateClientTypes(response.data.customerType)
          dispatch.apiStatus.setProcessing(false);
          return response
        })
        .catch(error => {
          dispatch.apiStatus.setProcessing(false);
          commonSegmentCalls.onApiFail(error, niAPI.clientType, 'Get client type API');
          dispatch.apiStatus.setRequestFailed({ ...error });
          return Promise.resolve({ success: false })
        });
      },
    };
  },
};
