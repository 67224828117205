import { niAPI } from 'constants/api';
import fetchApi from 'services/fetch';
import { isEmpty } from 'lodash';
import { removeWhiteSpace } from 'utils/utils';
import commonSegmentCalls from 'utils/segmentTracker/NI/commonSegmentCalls';

const initialState = {
  isExisting: false,
  docParams: {},
  clientInfo: {},
  homeDebts: [],
  mobileDebts: [],  
};

const getDocID = (id = '') => {
  switch (id.toLowerCase()) {
    case 'ci':
      return 'cid';
    case 'ruc':
      return 'ruc';
    case 'pas':
      return 'pp';
    default:
      return id;
  }
};

export const customerInquiry = {
  state: { ...initialState },
  reducers: {
    updateClientInfo(state, payload) {
      return { ...state, ...payload };
    },
    updateIsExisting(state, payload) {
      return {
        ...state,
        isExisting: payload,
      };
    },
    updateAgreementInformation(state, payload) {
      return {
        ...state,
        clientInfo: { ...state.clientInfo, ...payload },
      };
    },
    updateDocParams(state, payload) {
      return {
        ...state,
        docParams: {...state.docParams, ...payload},
      }
    },
    updateHomeDebts(state, payload) {
      return {
        ...state,
        homeDebts: [...state.homeDebts, ...payload],
      };
    },
    updateMobileDebts(state, payload) {
        return {
            ...state,
            mobileDebts: [...state.mobileDebts, ...payload],
        };
    },
    clearCurrentInfo() {
      return {
        ...initialState,
      };
    },
  },
  effects: (dispatch) => {
    return {
      async getClientInfo(params) {
        dispatch.apiStatus.setProcessing(true);
        this.clearCurrentInfo();
        const id = removeWhiteSpace(params?.inputDocumentNumber || '');
        this.updateDocParams({
          id,
          docType: params.documentType,
        });
        return fetchApi({
          method: 'get',
          url: niAPI.getClientsById.replace(':id', id)?.replace(':docID', getDocID(params.documentType)),
        })
          .then((response) => {
            if (isEmpty(response?.data)) {
              dispatch.apiStatus.setProcessing(false);
              return Promise.resolve({ success: true, existingService: false, debt: false });
            }
            this.updateIsExisting(true);
            this.updateAgreementInformation(response.data);
            const accountList = response?.data?.customerAccountList?.reduce((list, item) => {
              if (item?.accountList?.length > 0) {
                list = [...list, ...item.accountList];
              }
              return list;
            }, []);
            if (accountList.length) {
              const promise = accountList.map((info) => {
                return this.getClientDebts({
                  id: info?.billingAccountId,
                  type: info?.sourceSystemId,
                  isActive: info?.lifecycle?.isActive,
                  info,
                });
              });
              return Promise.allSettled(promise)
                .then((response) => {
                  dispatch.apiStatus.setProcessing(false);
                  console.log(response)
                  const filteredResponse = response
                    .filter((res) => res.value)
                    .map((res) => res.value)
                  if (filteredResponse.length) {
                    const homeDebts = filteredResponse.filter(debt => debt.type === 'home') || [];
                    const mobileDebts = filteredResponse.filter(debt => debt.type === 'mobile') || [];
                    this.updateHomeDebts(homeDebts);
                    this.updateMobileDebts(mobileDebts);
                    return Promise.resolve({ success: true, existingService: true, debt: true });
                  }
                  return Promise.resolve({ success: true, existingService: true, debt: false });
                })
                .catch(() => {
                  dispatch.apiStatus.setProcessing(false);
                  return Promise.resolve({ success: true, existingService: true, debt: false });
                });
            } else {
              dispatch.apiStatus.setProcessing(false);
              return Promise.resolve({ success: true, existingService: true, debt: false });
            }
          })
          .catch((error) => {
            if (error?.errorCode === 404) {
              dispatch.apiStatus.setProcessing(false);
              return Promise.resolve({ success: true, existingService: false, debt: false });
            } else {
              commonSegmentCalls.onApiFail(error, niAPI.getClientsById.replace(':id', id)?.replace(':docID', getDocID(params.documentType)), 'Get client details API');
              dispatch.apiStatus.setRequestFailed({ ...error });
              return Promise.resolve({ success: false });
            }
          });
      },
      async getClientDebts({ id, type, isActive, ...debtInfo }) {
        const url = type === 'SIGA' ? niAPI.getClientHomeDebts : niAPI.getClientMobileDebts;
        return fetchApi({
          method: 'get',
          url: url.replace(':id', id),
        })
          .then((response) => {
            return { ...response.data, numero: id, type: type === 'SIGA' ? 'home' : 'mobile', isActive, ...debtInfo?.info || {} };
          })
          .catch((err) => {
            commonSegmentCalls.onApiFail(err, url.replace(':id', id), 'Check debt API');
          });
      },

      async checkAppointment({ id }) {
        dispatch.apiStatus.setProcessing(true);
        return fetchApi({
          method: 'get',
          url: niAPI.checkAppointment.replace(':id', id),
        })
          .then((response) => {
            dispatch.apiStatus.setProcessing(false);
            if(response?.data?.errors?.[0]){
              dispatch.apiStatus.setRequestFailed({ message: 'No encontramos clientes con ordenes en agenda programada.' });
              return Promise.resolve({ success: false, data: null });
            }
            return Promise.resolve({ success: true, data: response?.data });
          })
          .catch((err) => {
            dispatch.apiStatus.setRequestFailed({ ...err });
            return Promise.resolve({ success: false });
          });
      },
    };
  },
};
