import commonSegmentCalls from 'utils/segmentTracker/NI/commonSegmentCalls';
import { deleteCookie } from 'utils/utils';
import { niAPI } from '../../constants/api';
import fetchApi from '../../services/fetch';

const initialState = {
  data: {},
  isLoggedIn: false,
};

export const auth = {
  state: { ...initialState },
  reducers: {
    updateAuthData(state, payload) {
      return {
        ...state,
        data: { ...payload },
      };
    },
    updateLoggedInStatus(state, payload) {
      return {
        ...state,
        isLoggedIn: payload,
      };
    },
  },
  effects: (dispatch) => {
    return {
      async authenticate(params) {
        dispatch.apiStatus.setProcessing(true);
        return fetchApi({
          method: 'POST',
          url: niAPI.accessToken,
          data: {
            code: params.code,
          },
        })
          .then((response) => {
            dispatch.auth.updateAuthData(response.data);
            localStorage.setItem('auth', btoa(JSON.stringify(response.data)));
            dispatch.apiStatus.setProcessing(false);
            localStorage.removeItem('type');
            return Promise.resolve({ success: true });
          })
          .catch((error) => {
            dispatch.apiStatus.setRequestFailed({ ...error });
            commonSegmentCalls.onApiFail(error, niAPI.accessToken, 'Access Token API');
            return Promise.resolve({ success: false });
          });
      },
      async authenticateSS() {
        dispatch.apiStatus.setProcessing(true);
        return fetchApi({
          method: 'POST',
          url: niAPI.accessToken_ss,
        })
          .then(() => {
            localStorage.setItem('type', btoa('discovery'));
            dispatch.apiStatus.setProcessing(false);
            return Promise.resolve({ success: true });
          })
          .catch((error) => {
            dispatch.apiStatus.setRequestFailed({ ...error });
            return Promise.resolve({ success: false });
          });
      },
      async checkMaintenance() {
        dispatch.apiStatus.setProcessing(true);
        return fetchApi({
          method: 'GET',
          url: niAPI.maintenance,
        })
          .then((response) => {
            dispatch.apiStatus.setProcessing(false);
            return Promise.resolve({ success: true, result: response?.data });
          })
          .catch((error) => {
            dispatch.apiStatus.setRequestFailed({ ...error });
            commonSegmentCalls.onApiFail(error, niAPI.maintenance, 'Maintenance API');
            return Promise.resolve({ success: false });
          });
      },
      async logout() {
        dispatch.apiStatus.setProcessing(true);
        return fetchApi({
          method: 'POST',
          url: niAPI.logout,
          data: {
            country: 'NI',
          },
        })
          .then(() => {
            dispatch.apiStatus.setProcessing(false);
            localStorage.clear();
            dispatch.auth.clearStore();
            deleteCookie();
            return Promise.resolve({ success: true });
          })
          .catch((error) => {
            commonSegmentCalls.onApiFail(error, niAPI.logout, 'Logout API');
            localStorage.clear();
            dispatch.auth.clearStore();
            deleteCookie();
            dispatch.apiStatus.setRequestFailed({ ...error });
            return Promise.resolve({ success: false });
          });
      },
      async clearStore() {
        dispatch.activation.clearActivation();
        dispatch.clientInformation.clearCurrentInfo();
        dispatch.inputAddress.clearStore();
        dispatch.offers.clearPackages();
        dispatch.documentUpload.clearDocumetDetails();
        dispatch.idDetails.clearIdDetails();
      },
    };
  },
};
