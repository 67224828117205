
import fetchApi from '../../services/fetch';
import { niAPI } from '../../constants/api';
import moment from 'moment';
import commonSegmentCalls from 'utils/segmentTracker/NI/commonSegmentCalls';

const initialState = {
    ordersList: []
};

export const salesReport = {
    state: { ...initialState },
    reducers: {
        updateOrdersList(state, payload) {
            return { ...state, ordersList: [...payload] };
        }
    },
    effects: dispatch => {
        return {
            async getOrdersList(params, state) {
                dispatch.apiStatus.setProcessing(true);
                const api = niAPI.getSalesPersonLeads.replace(':type', 'seller').replace(':id', state?.auth?.data?.pha_data?.id);
                return fetchApi({
                    method: 'get',
                    url: api,
                    params:{
                        seller: state?.auth?.data?.pha_data?.user,
                        startDate: moment().add(-30, 'd').format("YYYY-MM-DD"),
                        endDate: moment().format("YYYY-MM-DD"),
                    }
                })
                    .then(response => {
                        const data = response?.data?.ordersList;
                        if (data) {
                            dispatch.salesReport.updateOrdersList(data);
                        } else {
                            dispatch.salesReport.updateOrdersList([]);
                        }
                        dispatch.apiStatus.setProcessing(false);
                        return response
                    })
                    .catch(error => {
                        dispatch.apiStatus.setRequestFailed({ ...error });
                        commonSegmentCalls.onApiFail(error, api, 'Get Sales Report List API');
                        dispatch.apiStatus.setProcessing(false);
                        return error
                    });
            }
        }
    }
};