export const imageURLs = {
    backButton: '/images/icn-back.svg',
    darkBackButton: '/images/dark-icn-back.svg',
    logo: '/images/splash-logo.svg',
    package: '/images/icn-packages-on.png',
    arrowRight: '/images/icn-arrowright.png',
    sidebar: '/images/icn-hamburger.svg',
    individuals: '/images/icn-invididuales.svg',
    packages: '/images/icn-mas-paquetes.svg',
    cart: '/images/icn-cart.svg',
    success: '/images/icn-sucess-green.png',
    signatureHand: '/images/hand.svg',
    signaurePen:'/images/pen-icon.svg',
    successGraphic: '/images/Success_graphic@1.5x.svg',
    goLogo: '/images/goLogo.png',
    locationError: '/images/locationError.svg',
    noService: '/images/shutterstock_289206011@2x.png',
    confirmation: '/images/icn-confirmacion.svg',
    search: '/images/searchIcon.svg',
    searchFit: '/images/ico_search.svg',
    failed: '/images/failed.svg',
    leadCall: '/images/lead_call.svg',
    leadConfirmBanner: "/images/hero.png",
    cartImage: '/images/cart.svg',
    leadStatusSuccess: '/images/ico_status.svg',
    packPlay : '/images/ico_play.svg',
    packTv :  './images/ico_tv.svg',
    packAudio : './images/ico_audio.svg',
    packGlobe : './images/icon_globe.svg',
    packInfinite : './images/icon_infinite.svg',
    packFive : './images/icon_5.svg',
    packPhone : './images/icon_phone.svg',
    hfc: '/images/hfc.svg',
    hfcBanner: '/images/hfcbanner.png',
    hfcBannerMobile: '/images/mobilebanner.png',
    noLocation: '/images/ico_location-error.svg',
    location: '/images/ico_location.svg',
    leadConfirmBannerDesktop: "/images/hero-long@2x.png",
    warningIcon: "/images/ico_warning_2.svg",
    leadStatusConfirmed: '/images/ico_status_blue.svg',
    helper: '/images/ico__.svg',
    maintenanceWarning: '/images/ico_warning.svg',
    logout:'/images/ico_exit.svg',
    iconBack : 'images/ico_back.svg',
    iconMitigo: 'images/ico_mitigo.svg',
    iconStore: 'images/ico_store.svg',
    iconBank: 'images/ico_bank.svg',
    niSuccess : 'images/Icon(32x32).svg',
    niFail : 'images/niFail.svg',
    NiClosse : 'images/NiClosse.svg',
    orderProgress  : 'images/ico_progress.svg',
    warning: '/images/warning.svg',
    locationReset : '/images/ico_target.svg',
    conTriplePlay: 'images/con-triple-play.svg',
    locationIcon: 'images/location-icon.svg',
    crBackIcon: 'images/cr-back-icon.svg',
    signatureHandCr: '/images/ico_hand.svg',
    signaurePenCr:'/images/ico_edit.svg',
    mobileIcon: 'images/mobileIcon.svg',
    goQRLogo: 'images/goLogo.svg',
    goLogoCR : 'images/goLogoCR.svg',
    icoUser: 'images/ico_user.svg',
    whatsappIcon: '/images/whatsapp_icon.svg',
    starIcon: '/images/star_icon.svg',
    icoSecurity: '/images/ico_security.svg',
    clockIcon: '/images/ico_clock.svg',
    icoStatusBlue: '/images/ico_status_blue.svg',
    calSvg: 'images/ico_calendar_tran.svg',
    locSvg: 'images/ico_location_trans.svg',
    icoPDF: '/images/ico_PDF.svg',
    maintenance: '/images/maintenance.svg',
    cameraIcon: '/images/Camera.svg',
}
