import { niAPI } from '../../constants/api';
import { HN_TEXTS } from '../../constants';
import fetchApi from '../../services/fetch';
import commonSegmentCalls from 'utils/segmentTracker/NI/commonSegmentCalls';
import { ACTIVATION, OTP_FAILED } from 'constants/segmentEvents';
import { track } from 'utils/segmenTracker';

const initialState = {
  otpStatus: {
    received: false,
    verified: false,
  },
};

export const otp = {
  state: { ...initialState },
  reducers: {
    updateStatus(state, payload) {
      return {
        ...state,
        otpStatus: { ...state.otpStatus, ...payload },
      };
    },
    clearStatus(state) {
      return { ...initialState, otpStatus: {...initialState.otpStatus} };
    },
  },
  effects: (dispatch) => {
    return {
      async getOTP({ ...params }) {
        dispatch.apiStatus.setProcessing(true);
        this.clearStatus();
        return fetchApi({
          method: 'POST',
          url: niAPI.otp,
          data: { ...params },
        })
          .then(() => {
            dispatch.apiStatus.setProcessing(false);
            this.updateStatus({ received: true });
            return Promise.resolve({success:true});
          })
          .catch((error) => {
            dispatch.apiStatus.setRequestFailed({ ...error });
            track(OTP_FAILED, { journey: ACTIVATION, otp_channel: params.medium === 'email' ? 'email' : 'SMS' });
            commonSegmentCalls.onApiFail(error, niAPI.otp, 'Get OTP API');
            return Promise.resolve({success:false});
          });
      },
      async verifyOTP({ ...params }) {
        this.clearStatus();
        dispatch.apiStatus.setProcessing(true);
        return fetchApi({
          method: 'POST',
          url: niAPI.validateOTP,
          data: { ...params },
        })
          .then((response) => {
            const { data: { action = '' } = {} } = response;
            dispatch.apiStatus.setProcessing(false);
            if (action.toLowerCase() === HN_TEXTS.HN_OTP_VALIDATION_SUCCESS.toLowerCase()) {
              this.updateStatus({ verified: true });
              return Promise.resolve({success:true});
            }
            track(OTP_FAILED, { journey: ACTIVATION, otp_channel: params.medium === 'email' ? 'email' : 'SMS' })
            dispatch.apiStatus.setRequestFailed({
              message: 'Tu código es incorrecto, inténtalo de nuevo',
            });
          })
          .catch((error) => {
            dispatch.apiStatus.setRequestFailed({ ...error });
            track(OTP_FAILED, { journey: ACTIVATION, otp_channel: params.medium === 'email' ? 'email' : 'SMS' })
            commonSegmentCalls.onApiFail(error, niAPI.validateOTP, 'Validate OTP API');
            return Promise.resolve({success:false});
          });
      },
    };
  },
};
