import { removeWhiteSpace } from 'utils/utils';

export const clientAdditionalInformation = ({ pha_data, alternateNumber }) => {
    return {
        "activateService": {
            "createBasketAndAddItemsMutation": {
                "basketCreateMutation": {
                    "included": {
                        "newInstance": {
                            "attributes": {
                                "leadStep": "customer_second_phone_number",
                                "salesContext": {
                                    "salespersonCode": `${pha_data?.user}`
                                }
                            }
                        }
                    }
                }
            },
            "createCustomerMutation": {
                "individualsCreateMutation": {
                    "included": {
                        "newInstance": {
                            "included": {
                                "contactMedia": [
                                    {
                                        "atrributes": {
                                            "medium": {
                                                "telephoneNumber": {
                                                    "number": removeWhiteSpace(alternateNumber),
                                                    "numberType": "R"
                                                }
                                            }
                                        }
                                    }
                                ]
                            }
                        }
                    }
                }
            }
        }
    }
}