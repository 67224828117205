import { niAPI } from "constants/api";
import fetchApi from "services/fetch";
import commonSegmentCalls from "utils/segmentTracker/NI/commonSegmentCalls";

const initialSelection = {
    selectedPackage: {},
    selectedAddons: [],
    addons: [],
    prices: {},
    quantity: {},
}

const initialState = {
    ...initialSelection,
    creditProfile: {},
    offers: {},
    backup: {},
    allOffersView: false,
}

export const offers = {
    state: { ...initialState },
    reducers: {
        allOffersView(state, payload) {
            return {
                ...state,
                allOffersView: payload
            }
        },
        updateCreditProfile(state, payload) {
            return {
                ...state,
                creditProfile: { ...payload }
            };
        },
        updateOffers(state, payload) {
            return {
                ...state,
                offers: { ...payload }
            };
        },
        updateSelectedPackage(state, payload) {
            return {
                ...state,
                selectedPackage: { ...state.selectedPackage, ...payload }
            }
        },
        updateSelectedAddons(state, payload) {
            const checkedData = state.selectedAddons.find(selected => selected.prodId === payload.prodId) || state.selectedAddons.find(selected => selected.name === payload.name)
            let filteredSelection;
            if (checkedData) {
                const currfilteredSelection = state.selectedAddons.
                    filter(selection => selection.name.toLowerCase() !== payload.name.toLowerCase())
                    .filter(selected => selected.prodId !== payload.prodId);
                if (payload?.type === 'checkbox' && !payload.checked) {
                    filteredSelection = currfilteredSelection;
                }
                else {
                    filteredSelection = [...currfilteredSelection, payload]
                }
            } else {
                filteredSelection = [...state.selectedAddons, payload];
            }

            return {
                ...state,
                selectedAddons: filteredSelection
            }
        },
        updateAddons(state, payload) {
            return {
                ...state,
                addons: [...state.addons, ...payload]
            }
        },
        updateAddonsQuantity(state, payload) {
            return {
                ...state,
                quantity: { ...state.quantity, ...payload }
            }
        },
        updatePrice(state, payload) {
            return {
                ...state,
                prices: { ...state.prices, ...payload }
            }
        },
        updateAddonsPrice(state, payload) {
            state.addons.filter(item=>item.prodId == payload.prodId)[0].price = payload.price;
            return {
                ...state,
            } 
        },
        clearSelection(state) {
            return { ...state, ...initialSelection }
        },
        clearPackages() {
            return {
                ...initialState
            }
        },
        removeAll(state, payload) {
            const checkedData = state.selectedAddons.find(selected => selected.name === payload)
            let filteredSelection;
            if (checkedData) {
                filteredSelection = state.selectedAddons.filter(selection => selection.name.toLowerCase() !== payload.toLowerCase())
            } else {
                filteredSelection = state.selectedAddons;
            }
            return {
                ...state,
                selectedAddons: filteredSelection
            }
        },
        clearBackupPackages(state) {
            return { ...state, backup: {} }
        },
        backupPackages(state) {
            return { ...state, backup: {...state, backup: {}} } 
        },
        restorePackages(state) {
            return { ...state, ...state.backup, backup:{} } 
        },
    },
    effects: dispatch => {
        return {
            async getCreditProfile(params, state) {
                const type = state?.activation?.clientDetails?.documentType === 'RUC' ? '1' : '2';
                const id = state?.activation?.clientDetails?.inputDocumentNumber;
                dispatch.apiStatus.setProcessing(true);
                return fetchApi({
                    method: "GET",
                    url: niAPI.creditProfile.replace(':type',type).replace(':id',id)
                })
                    .then(response => {
                        this.updateCreditProfile(response.data);
                        dispatch.apiStatus.setProcessing(false);
                        return Promise.resolve({success: true});
                    })
                    .catch(error => {
                        dispatch.apiStatus.setRequestFailed({ ...error });
                        commonSegmentCalls.onApiFail(error, niAPI.creditProfile.replace(':type',type).replace(':id',id), 'Get credit profile API');
                        return Promise.resolve({success: false});
                    });
            },
            async getOffers(params, state) {
                dispatch.apiStatus.setProcessing(true);
                let reqParams = { channelID: '521' }
                if(params?.all_offers == 1) {
                    this.allOffersView(true);
                    reqParams = { ...reqParams, all_offers: 1 }
                } else {
                    this.allOffersView(false);
                    const creditID = state?.offers?.creditProfile?.creditProfile?.creditScore;
                    reqParams = {
                        ...reqParams,
                        nodeId: state?.inputAddress?.coverageInfo?.nodeid,
                        identificationType: 'Other',
                        identificationValue: state?.activation?.clientDetails?.inputDocumentNumber,
                        creditID: isNaN(Number(creditID)) ? '010': creditID,
                    }
                    if(params?.income){
                        reqParams['currency'] = params?.currency;
                        reqParams['amount'] = params?.income;
                    }
                }
                
                return fetchApi({
                    method: "GET",
                    url: niAPI.offers,
                    params: { ...reqParams }
                })
                    .then(response => {
                        this.updateOffers(response.data);
                        dispatch.apiStatus.setProcessing(false);
                        return Promise.resolve({success: true});
                    })
                    .catch(error => {
                        if(error?.errorCode === 404){
                            dispatch.apiStatus.setProcessing(false);
                            return Promise.resolve({success:true, noOffer:true})
                        } else {
                            dispatch.apiStatus.setRequestFailed({ ...error });
                            commonSegmentCalls.onApiFail(error, niAPI.offers, 'Get offers API');
                            return Promise.resolve({success: false});
                        }
                    });
            },
        };
    }
}
