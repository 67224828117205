export const customerDocumentsParams = ({ pha_data, ...data }) => {
  return {
    activateService: {
      createBasketAndAddItemsMutation: {
        basketCreateMutation: {
          included: {
            newInstance: {
              attributes: {
                leadStep: 'customer_documents',
                salesContext: {
                  salespersonCode: `${pha_data?.user}`,
                },
              },
            },
          },
        },
      },
      createCustomerMutation: {
        individualsCreateMutation: {
          included: {
            newInstance: {
              included: {
                identifications: [...data.attributes],
                identifications_link: data.repoLink,
                contract_number: `${data?.contractId}` || '',
              },
            },
          },
        },
      },
    },
  };
};
