export const ACTIVATION = 'activation';
export const CAPTURE_ELEMENT_FRNTID = 'ID front';
export const CAPTURE_ELEMENT_REAR = 'ID back';
export const ORDERSUCCESS = 'OrderSuccess';

//events
export const HOMEACTIVATOR_PORTAL_LOADED = 'Home Activator Portal Loaded';
export const LOGIN_CLICKED = 'Login Clicked';
export const LOGIN_ATTEMPTED = 'Login Attempted';
export const LOGIN_SUCCEEDED = 'Login Succeeded';
export const LOGIN_FAILED = 'Login Failed';
export const HOMEACTIVATOR_MENU_LOADED = 'Home Activator Menu Loaded';
export const PRODUCT_LIST_VIEWED = 'Product List Viewed';
export const PRODUCT_CLICKED = 'Product Clicked';
export const CONTINUE_SHOPPING = 'Continue Shopping Clicked';
export const MODIFY_SELECTION = 'Modify Selection Clicked';
export const REMOVE_SELECTION = 'Remove Selection Clicked';
export const PHOTO_CAPTURE_REQUESTED = 'Photo Capture Requested';
export const PHOTO_CAPTURE_ATTEMPTED = 'Photo Capture Attempted';
export const PHOTO_CAPTURE_COMPLETED = 'Photo Capture Completed';
export const PHOTO_CAPTURE_RETAKE = 'Photo Capture Retake Clicked';
export const PHOTO_CAPTURE_FAILED = 'Photo Capture Failed';
export const ID_INFORMATION_SUBMITTED = 'ID Information Submitted';
export const OTP_LOADED = 'OTP Loaded';
export const OTP_FAILED = 'OTP Failed';
export const OTP_SKIPPED = 'OTP Skipped';
export const OTP_SUBMITTED = 'OTP Submitted';
export const OTP_RESEND = 'OTP Resend Requested';
export const CONTRACT_SIGNATURE_LOADED = 'Contract Signature Loaded';
export const CONTRACT_SIGNATURE_SUBMITTED = 'Contract Signature Submitted';
export const SCHEDULE_VISIT_LOADED = 'Schedule New Visit Loaded';
export const SCHEDULE_VISIT_SUBMITTED = 'Schedule New Visit Submitted';
export const SCHEDULE_VISIT_FAILED = 'Schedule New Visit Failed';
export const ORDER_CONFIRMED = 'Order Confirmed';
export const ORDER_FAILED = 'Order Failed';
export const ORDER_SUCCEDED = 'Order Succeeded';
export const ADDONS_MORE_DETAILS_CLICKED = 'Addons More Details Clicked';
export const SERVER_RESPONSE_FAILED = 'Server Response Failed';
export const MESSAGE_DISPLAYED = 'Message Displayed';
export const NEW_LEAD_SELECTED = 'New Lead Selected';
export const SALES_REPORT_CLICKED = 'Sales Report Clicked';
export const LOGOUT_ATTEMPTED = 'Logout Attempted';
export const CLIENT_DETAILS_LOADED = 'Client Details Loaded';
export const CLIENT_DETAILS_SUBMITTED = 'Client Details Submitted';
export const FORM_ERROR_NOTIFIED = 'Form Error Notified';
export const ACTIVE_TRANSACTION_ATTEMPTED = 'Active Transaction Attempted';
export const EXISTING_SERVICES_LOADED = 'Existing Services Loaded';
export const EXISTING_SERVICE_DETAILS_LOADED = 'Existing Service Details Loaded';
export const ADD_SERVICES_CLICKED = 'Add Services Clicked';
export const CHANGE_PLANS_CLICKED = 'Change Plans Clicked';
export const ACCOUNT_SELECTED = 'Account Selected';
export const INSTRUCTIONS_LOADED = 'Instructions Loaded';
export const INSTRUCTIONS_ACCEPTED = 'Instructions Accepted';
export const ADDRESS_FORM_LOADED = 'Address Form Loaded';
export const ADDRESS_FORM_SUBMITTED = 'Address Form Submitted';
export const ADDRESS_LIST_LOADED = 'Address List Loaded';
export const ADDRESS_LIST_SUBMITTED = 'Address List Submitted';
export const SERVICE_AVAILABILITY_DISPLAYED = 'Service Availability Displayed';
export const INELIGIBILITY_NOTIFIED = 'Ineligibility Notified';
export const ADDONS_SUBMITTED = 'Addons Submitted';
export const ORDER_DETAILS_LOADED = 'Order Details Loaded';
export const ORDER_STARTED = 'Order Started';
export const ID_INFORMATION_LOADED = 'ID Information Loaded';
export const ORDER_COMPLETED = 'Order Completed';
export const SALES_REPORT_DISPLAYED = 'Sales Report Displayed';
export const DETAILS_CLICKED = 'Details Clicked';
export const EXTERNAL_LINK_CLICKED = 'External Link Clicked';
export const OTP_CHANNEL_SMS = 'SMS';
export const OTP_CHANNEL_EMAIL = 'email';
export const EXTERNAL_LINK_LOADED = 'External Link Loaded';
export const DOCUMENT_UPLOAD_LOADED = 'Document upload loaded';
export const DOCUMENT_UPLOAD_SUBMITTED = 'Document upload submitted';
export const UTILITY_INFORMATION_LOADED = 'Utility Information Loaded';
export const UTILITY_INFORMATION_SUBMITTED = 'Utility Information Submitted';
export const LEAD_CLICKED = 'Lead Clicked';
export const LEAD_DETAILS_VIEWED = 'Lead Details Viewed';
export const LEAD_UPDATE_CLICKED = 'Lead Update Clicked';
export const LEAD_UPDATE_SUBMITTED = 'Lead Update Submitted';
export const LEAD_UPDATE_CANCELLED = 'Lead Update Cancelled';
export const REPORTING = 'reporting';
export const INELIGIBILITY_SCREEN_LOADED = 'Ineligibility Screen Loaded';
export const ORDER_SUBMITTED = 'Order Submitted';
export const ORDER_DETAILS_CLICKED = 'Order Details Clicked';
export const INSTRUCTION_ACCEPTED = 'Instructions Accepted';
export const BACK_CLICK = 'Back Option Click';
export const SERVER_RESPONSE_OK = 'Server Response OK';
