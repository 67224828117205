export const customerLocationParams = ({ latitude, longitude, zone, pha_data }) => {
    return {
        "activateService": {
            "createBasketAndAddItemsMutation": {
                "basketCreateMutation": {
                    "included": {
                        "newInstance": {
                            "attributes": {
                                "leadStep": "customer_location",
                                "salesContext": {
                                    "salespersonCode": pha_data?.user
                                }
                            }
                        }
                    }
                }
            },
            "createCustomerMutation": {
                "individualsCreateMutation": {
                    "included": {
                        "newInstance": {
                            "included": {
                                "locations": [
                                    {
                                        "attributes": {
                                            zone,
                                            latitude: latitude.toFixed(7),
                                            longitude: longitude.toFixed(7)
                                        }
                                    }
                                ]
                            }
                        }
                    }
                }
            }
        }
    }
}