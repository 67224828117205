import axios from 'axios';
import { deleteCookie } from '../utils/utils';
import { appConfig } from '../config/appConfig';
import _ from 'lodash';
import qs from 'qs';
import uuidv1 from 'uuid/v1';
import { getEncryptedKeys, signHeader, decryptHeader } from 'utils/secureTransfer';
import secureStorage from 'utils/secureWebStorage';
import { encPortals } from 'constants/encConstants';

const fetchApi = axios.create({
  baseURL: appConfig.apiURL,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

fetchApi.interceptors.request.use(async (request) => {
  if (encPortals.includes(process.env.REACT_APP_COUNTRY) && !request.headers.exclude) {
    const state = uuidv1();
    const nonce = uuidv1();
    const identity = uuidv1();
    secureStorage.setItem(identity, state);

    let { data, headers, ...otherConfigValues } = request;
    const time = Date.now();

    const queryParts = request.url.split('?');
    const querystring = queryParts.length === 2 ? qs.parse(queryParts[1]) : {};

    let payloadHeaders = {};
    let updatedConfig = request;
    const { encryptedState, encryptedNonce } = await getEncryptedKeys(state, nonce);

    payloadHeaders['nonce'] = encryptedNonce;
    payloadHeaders['state'] = encryptedState;
    payloadHeaders['time'] = time;

    const payloadForSign = {
      query: querystring,
      body: data ? data : {},
      headers: payloadHeaders ? payloadHeaders : {},
    };
    let signature;
    try {
      signature = await signHeader(payloadForSign);
    } catch (e) {
      console.log(e);
    }
    updatedConfig = {
      ...otherConfigValues,
      data,
      headers: { ...headers, signature, ...payloadHeaders, identity },
    };
    return updatedConfig;
  }
  return request;
});

fetchApi.interceptors.response.use(
  async (res) => {
    let response = res;
    if (encPortals.includes(process.env.REACT_APP_COUNTRY) && !response?.config?.headers?.excludeRes) {
      const data = await decryptHeader(res?.data);
      response = { ...response, data: JSON.parse(data) };
    }
    if (
      encPortals.includes(process.env.REACT_APP_COUNTRY) &&
      !response?.config?.headers?.exclude &&
      response?.headers?.state &&
      response?.headers?.state !== secureStorage.getItem(response?.config?.headers?.identity)
    ) {
      localStorage.clear();
      deleteCookie();
      window.location.href = '/';
      return Promise.reject({ message: 'No autorizado' });
    }
    if (response.data.code) {
      return Promise.reject({ errorCode: response.data.code, message: response.data.message, data: response.data });
    }
    return response;
  },
  async (err) => {
    let error = err;
    if (error?.response?.status === 400) {
      const formattedMessage =
        _.get(error.response, 'data.errors[0].message', '') ||
        _.get(error.response, 'data.error.errors[0].message', '');
      const message = error.response.data.error
        ? error.response.data.error.message
        : error.response.data.status
        ? _.get(
            error.response,
            'data.status[0].errorDetails',
            'Algo salió mal, por favor intente después de algún tiempo.'
          )
        : 'Algo salió mal, por favor intente después de algún tiempo.';
      return Promise.reject({
        errorCode: error.response.status,
        message: formattedMessage || message,
        rawResponse: error.response.data,
      });
    } else if (error.response.status === 401) {
      if (window.location.pathname === '/cobertura/na/mapa') {
        window.history.go(-1);
        return;
      }
      localStorage.clear();
      deleteCookie();
      window.location.href = '/';
      return Promise.reject({
        errorCode: error.response.status,
        message: 'No autorizado',
        rawResponse: error.response.data,
      });
    } else if (error.response.status === 403) {
      return Promise.reject({
        errorCode: error.response.status,
        message: 'El app no cuenta con acceso para utilizar el api',
        rawResponse: error.response.data,
      });
    } else if (error.response.status === 404) {
      return Promise.reject({
        errorCode: error.response.status,
        message: 'No hay resultados',
        rawResponse: error.response.data,
      });
    } else if (error.response.status === 412 || error.response.status >= 500) {
      return Promise.reject({
        errorCode: error.response.status,
        message: 'Error de servidor interno',
        rawResponse: error.response.data,
      });
    } else {
      const errMsg = typeof error?.response?.data === 'string' ? error?.response?.data : 'Error de servidor interno';
      return Promise.reject({
        errorCode: error.response.status,
        message: error === 'Network Error' ? 'Error de red' : errMsg,
        rawResponse: error.response.data || {},
      });
    }
  }
);

export default fetchApi;
