const dict = {
  ñ: 'n',
  á: 'a',
  é: 'e',
  í: 'i',
  ó: 'o',
  ú: 'u',
  Á: 'A',
  É: 'E',
  Í: 'I',
  Ñ: 'N',
  Ó: 'O',
  Ú: 'U',
  Ü: 'U',
  ü: 'u',
};
export const accentReplace = (text = '') => {
  return text.replace(/[^\w ]/g, function (char) {
    return dict[char] || char;
  });
};
