import { niAPI } from 'constants/api';
import fetchApi from 'services/fetch';
import { isEmpty } from 'lodash';
import { removeWhiteSpace } from 'utils/utils';
import commonSegmentCalls from 'utils/segmentTracker/NI/commonSegmentCalls';

const creditProfileRegex = /^(?<credScore>[0-9]{3}), (?<credType>[a-zA-Z\s]*), (?<id>[0-9a-zA-Z]*), (?<name>\([a-zA-Z\s]*\))-(?<serviceType>[a-zA-Z]{2})$/

const initialState = {
  docParams: {},
  creditInfo: {},
};

const getDocID = (id = '') => {
  switch (id.toLowerCase()) {
    case 'ci':
      return '2';
    case 'ruc':
      return '1';
    case 'pas':
      return '2';
    default:
      return id;
  }
};

export const checkCredit = {
  state: { ...initialState },
  reducers: {
    updateCreditInfo(state, payload) {
      return { ...state, creditInfo: payload };
    },
    updateDocParams(state, payload) {
      return {
        ...state,
        docParams: { ...state.docParams, ...payload },
      };
    },
    clearCurrentInfo() {
      return {
        ...initialState,
      };
    },
  },
  effects: (dispatch) => {
    return {
      async getCreditInfo(params) {
        dispatch.apiStatus.setProcessing(true);
        this.clearCurrentInfo();
        const id = removeWhiteSpace(params?.inputDocumentNumber || '');
        this.updateDocParams({
          id,
          docType: params.documentType,
        });
        return fetchApi({
          method: 'get',
          url: niAPI.creditProfile.replace(':id', id)?.replace(':type', getDocID(params.documentType)),
        })
          .then((response) => {
            if (isEmpty(response?.data)) {
              dispatch.apiStatus.setProcessing(false);
              return Promise.resolve({ success: false });
            }
            const { creditProfile = {}, name, characteristic } = response.data;
            const { creditScore } = creditProfile;
            const validName = name && typeof name === 'string';
            const validCredScore = creditScore && typeof creditScore === 'string';
            if (validCredScore) {
              const findFullCreditProfile =
                characteristic.find((dataItem) => dataItem.name === 'creditProfileCode')?.value || '';
              const serviceCode = creditProfileRegex.exec(findFullCreditProfile);
              this.updateCreditInfo({
                ...response.data,
                creditScore,
                credType: serviceCode?.groups?.credType,
                serviceType: serviceCode?.groups?.serviceType,
              });
              return Promise.resolve({ success: true });
            }
            if (!validName && !validCredScore) {
              return Promise.resolve({ success: true, invalidId: true });
            }
            return Promise.resolve({ success: false });
          })
          .catch((error) => {
            commonSegmentCalls.onApiFail(
              error,
              niAPI.creditProfile.replace(':id', id)?.replace(':type', getDocID(params.documentType)),
              'Get credit profile'
            );
            dispatch.apiStatus.setRequestFailed({ ...error });
            return Promise.resolve({ success: false });
          });
      },
    };
  },
};
