
import fetchApi from '../../services/fetch';
import { niAPI } from '../../constants/api';
import commonSegmentCalls from 'utils/segmentTracker/NI/commonSegmentCalls';

const initialState = {
    coverageInfo: null,
    location: {},
    locationsData: null
};

export const inputAddress = {
    state: { ...initialState },
    reducers: {
        updateCoverageInfo(state, payload) {
            return {
                ...state,
                coverageInfo: payload,
            };
        },
        updateLocation(state, payload) {
            return { ...state, location: payload };
        },
        updateLocationData(state, payload) {
            return { ...state, locationsData: payload };
        },
        clearStore(){
            return { ...initialState };
        }
    },
    effects: dispatch => {
        return {
            async getCoverage(params) {
                dispatch.apiStatus.setProcessing(true);
                return fetchApi({
                    method: 'get',
                    url: niAPI.niCoverage,
                    params : {
                        'geolocation.longitude' : params.path.longitude,
                        'geolocation.latitude' : params.path.latitude
                    }
                })
                    .then(response => {
                        dispatch.inputAddress.updateCoverageInfo(response.data);
                        params.updateCoverage && params.updateCoverage()
                        dispatch.apiStatus.setProcessing(false);
                        return response
                    })
                    .catch(error => {
                        dispatch.inputAddress.updateCoverageInfo({ coverage: false });
                        commonSegmentCalls.onApiFail(error, niAPI.niCoverage, 'Check coverage API');
                        params.updateCoverage && params.updateCoverage()
                        dispatch.apiStatus.setProcessing(false);
                        return error
                    });
            },
            async getLocationData() {
                dispatch.apiStatus.setProcessing(true);
                return fetchApi({
                    method: "GET",
                    url: niAPI.getLocations
                })
                    .then(response => {
                        dispatch.apiStatus.setProcessing(false);
                        dispatch.inputAddress.updateLocationData(response.data);
                    })
                    .catch(error => {
                        dispatch.apiStatus.setProcessing(false);
                        commonSegmentCalls.onApiFail(error, niAPI.getLocations, 'Get location data API');
                        dispatch.apiStatus.setRequestFailed({ ...error });
                        return Promise.resolve({ success: false })
                    });
            }
        }
    }
};