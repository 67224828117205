import { track, generateServerResponseError, generateMessageDisplayedSegment, globalTrackValues, updateGlobalTracker, identify } from 'utils/segmenTracker';
import { 
    SERVER_RESPONSE_FAILED,
    MESSAGE_DISPLAYED,
    ACTIVATION,
} from "constants/segmentEvents";
import { defaultTrackValuesNI } from './defaultTrackValuesNI';

const identifyNI = () => {
    const authData = localStorage.getItem('auth');
    if (authData) {
        const { pos_msisdn, uniqueIdentifier } = JSON.parse(atob(authData));
        identify(uniqueIdentifier, {
            msisdn: pos_msisdn,
            country: 'NI',
        })
    }
}

const onApiFail = (err,api,event) => {
    const currentPage = globalTrackValues['NI'].page_name;
    track(SERVER_RESPONSE_FAILED, {...generateServerResponseError(err, ACTIVATION, currentPage, api, event )})
    track(MESSAGE_DISPLAYED, {...generateMessageDisplayedSegment(err, ACTIVATION, event )})
}

const clearAllTracks = () => {
    const authData = localStorage.getItem('auth');
    if (authData) {
        const data = JSON.parse(atob(authData));
        updateGlobalTracker({  
            ...defaultTrackValuesNI,
            poa_id: data?.pha_data?.id || '',
            poa_first_name: data?.pha_data?.name || '',
            poa_last_name: data?.pha_data?.lastName || '',
            poa_sales_channel: data?.pha_data?.channel || '',
        })
    }
}

export default { identifyNI, onApiFail, clearAllTracks }