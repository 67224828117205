export const pageURLs = {
    home: '/inicio',
    salesReport: '/ventas-reporte',
    salesDetail: '/ventas-detalle',
    inputAddress: '/ingresa-direccion',
    coverageStatus: '/cobertura-estado',
    coverageSuccess: '/cobertura-exito',
    mainMenu: '/menu-principal',
    confirmPackage: '/confirmar-seleccion-paquete',
    checkout: '/proceder-pago',
    idScanFront: '/id-escaneo-frontal',
    idScanRear: '/id-escaneo-posterior',
    idReview: '/id-revisar',
    contractForm: '/contrato-formulario',
    verifyPhone: '/verificar-telefono',
    verifyEmail: '/verificar-correo',
    termsConditions: '/terminos-y-condiciones',
    confirmAdditionalClauses: '/autorizaciones-y-firma',
    contractSignatureInfo: '/firma-de-autorizaciones',
    signatureClient: '/firma-del-cliente',
    vendorSignatureInfo: '/firma-de-vendedor',
    signatureVendor: '/firma-del-vendedor',
    documentUpload: '/documentos',
    scheduleVisit: '/agendar-visita',
    confirmOrder: '/confirmar-orden',
    orderSuccess: '/orden-exito',
    orderFailed: '/orden-fallida',
    logout: '/cerrar-sesion',
    clientInfo: '/información-del-cliente',
    clientInfoFailed: '/la-información-del-cliente-falló',
    incomeValidation: '/ingresos-del-cliente',
    maintenanace: '/mantenimiento',
}

export const pageURL_HN = {
  home: '/inicio',
  clientInformation: '/informacion-del-cliente',
  activeTransaction: '/cliente-con-transaccion-activa',
  existingServices: '/clientes-con-servicios-existentes',
  locationServices: '/activacion-del-servicio-de-ubicacion',
  mitigoRedirection: '/mitigo-redirection',
  inputAddress: '/ingresa-direccion',
  map: '/map',
  offerNotAvailable: '/oferta-no-disponible',
  products: '/seleccion-de-producto',
  addons: '/confirmar-seleccion-paquete',
  idScanFront: '/id-escaneo-frontal',
  idReview: '/id-revisar',
  checkout: '/proceder-pago',
  idNotValid: '/identificacion-no-validado',
  contractInfo: '/contrato-formulario',
  verifyPhone: '/verificar-telefono',
  verifyEmail: '/verificar-correo',
  activation: '/confirmar-orden',
  orderSuccess: '/orden-exito',
  salesReport: '/ventas-reporte',
  scheduleVisit: '/agendar-visita',
  clientInfoFailed: '/la-información-del-cliente-falló',
};

export const pageURL_GT = {
  ...pageURL_HN,
  activeTransaction: '/existing-debt',
  addressList: '/address-list',
  idScanRear: '/id-escaneo-posterior',
  billUpload: '/bill-upload',
  billConfirm: '/bill-confirm',
  documentUpload: '/documentos',
  termsConditions: '/terminos-y-condiciones',
  contractSignatureInfo: '/firma-de-autorizaciones',
  signatureClient: '/firma-del-cliente',
  paymentReceipt: '/payment-receipt',
  orderFailed: '/orden-fallida',
  existingServicesModify: '/clientes-con-servicios-existentes-modify',
  updateAddons: '/update-seleccion-paquete',
  changePlan: '/cambiar-plan',
  confirmOrder: '/update-confirmar-orden',
  updatedOrderSuccess: '/update-orden-exito',
  maintenance: '/mantenimiento',
};

export const pageURL_NI = {
    ...pageURL_HN,
    maintenance: '/mantenimiento',
    home: '/menu',
    inputAddress: '/ingresa-direccion',
    debt: '/existing-debt',
    appointmentSchedule: '/calendario-de-citas',
    successfulTransaction: '/orden-exito',
    checkoutSuccess: '/pagar-al-vendedor',
    clientAdditionalInfo: '/client-additional-info',
    idScanRear: '/id-escaneo-posterior',
    documentUpload: '/documentos',
    quickQueries: '/consultas-rapidas',
    customerInquiry: '/consulta-cliente-factura',
    customerInquiryList: '/consulta-cliente-factura/existing-debt',
    customerInquiryError: '/consulta-cliente-factura/cliente-no-existente',
    checkCredit: '/consulta-buro-de-credito',
    checkCreditInfo: '/consulta-buro-de-credito/info',
    checkCreditInvalidId: '/consulta-buro-de-credito/invalid-id',
    checkCreditNotFound: '/consulta-buro-de-credito/not-exist',
    orderInquiry: '/consulta-de-orden'
}

export const pageURL_CO = {
  leadToCall: '/te-llamamos',
  leadToCallConfirmation: '/te-llamamos-confirmacion',
  hfcFailed: '/diferente-hfc',
  inputAddress: '/direccion-detallada',
  noCoverage: '/direccion-sin-cobertura',
  noAddress: '/direccion-no-identificada',
  personalInfo: '/informacion-personal',
  packages: '/seleccion-oferta',
  orderSuccess: '/solicitud-recibida',
  inputSingleLine: '/direccion-sencilla',
  inputMap: '/direccion-mapa',
  existingClient: '/opciones-cliente-existente',
  existingClientNewDirection: '/cliente-nueva-direccion',
  creditScoreRejected: '/perfil-financiero-incompleto',
  creditScoreRejectedLow: '/perfil-financiero-insuficiente',
  selectMobileNum: '/seleccione-número-móvil',
  validateOtp: '/validacion-otp',
  otpExpired: '/validacion-otp-expiro',
  incorrectOtp: '/validacion-otp-incorrecta',
  securityQs: '/pregunta-seguridad/:number',
  varificationSuccess: '/validacion-preguntas-correcta',
  antiFraudValidationFail: '/perfil-validacion-incompleto',
  verificationError: '/validacion-preguntas-incorrecta',
  processShedule: '/agendamiento-instalacion',
  termsConditions: '/aceptacion-oferta',
  unexpectedError: '/error-servidor-id',
  validationSuccess: '/orden-recibida',
  maintenance: '/mantenimiento',
  otpSkip:'/preguntas-de-seguridad'
};

export const pageURL_CR = {
  clientInformation: '/informacion-personal',
  existingDebt: '/deuda-pendiente',
  invalidClient: '/id-invalido',
  existingClient: '/cliente-existente',
  inputAddress: '/direccion-hogar',
  verifyPhone: '/OTP-telefono',
  verifyEmail: '/OTP-email',
  idScanFront: '/id-escaneo-frontal',
  inputMap: '/direccion-mapa',
  noCoverage: '/direccion-sin-cobertura',
  orderSuccess: '/solicitud-recibida',
  confirmOrder: '/confirmar-orden',
  clientIdentification: '/identificacion-del-cliente',
  offerSelection: '/seleccion-oferta',
  appointmentSchedule: '/calendario-de-citas',
  contractSignatureInfo: '/firma-de-autorizaciones',
  signatureClient: '/firma-del-cliente',
  paymentRedirect: '/payment-redirect',
  paymentFailed: '/payment-failed',
  leadToCall: '/te-llamamos',
  leadCallConfirmation: '/te-llamamos-confirmacion'
}

export const pageURL_PY = {
  ...pageURL_HN,
  error: '/error',
  idScanRear: '/id-escaneo-posterior',
  salesManagement: '/gestion-de-ventas',
  transactionInProgress: '/transaccion-en-progreso',
  confirmOrder: '/confirmar-orden',
  orderProgress: '/order-progress',
  orderFailed: '/orden-fallida',
  orderFail: '/order-fail',
};
