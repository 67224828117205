import { UIConfig } from './UIConfig';
import { pageURLs } from '../constants/URLs';
import HN_ROUTES from './routes/routes_HN';
import PY_ROUTES from './routes/routes_PY';
import GT_ROUTES from './routes/routes_GT';
import NI_ROUTES from './routes/routes_NI';
import CR_ROUTES from './routes/routes_CR';
import { CO_ROUTES } from './routes/routes_CO';

export const routes = {
  NI: NI_ROUTES,
  SV: {
    appRoutes: [
      {
        path: '/',
        component: 'Landing',
      },
      {
        path: '/redirect',
        component: 'Landing',
      },
    ],

    privateAppRoutes: [
      {
        path: pageURLs.clientInfo,
        component: 'ClientInfo',
      },
      {
        path: pageURLs.clientInfoFailed,
        component: 'ClientInfoFailed',
      },
      {
        path: pageURLs.inputAddress,
        component: 'InputAddress',
        formData: UIConfig['InputAddress'],
      },
      {
        path: pageURLs.coverageStatus,
        component: 'CoverageStatus',
      },
      {
        path: pageURLs.coverageSuccess,
        component: 'CoverageSuccess',
      },
      {
        path: pageURLs.mainMenu,
        component: 'MainMenu',
        formData: UIConfig['MainMenu'],
      },
      {
        path: pageURLs.checkout,
        component: 'ProductCheckout',
        formData: UIConfig['ProductCheckout'],
      },
      {
        path: pageURLs.confirmPackage,
        component: 'ConfirmPackage',
        formData: UIConfig['ConfirmPackage'],
      },
      {
        path: pageURLs.salesReport,
        component: 'SalesReport',
        formData: UIConfig['SalesReport'],
      },
      {
        path: `${pageURLs.salesDetail}/:id`,
        component: 'SalesDetail',
        formData: UIConfig['SalesDetails'],
      },
      {
        path: pageURLs.home,
        component: 'HomePage',
        formData: UIConfig['HomePage'],
      },
      {
        path: pageURLs.confirmOrder,
        component: 'ConfirmOrder',
      },
      {
        path: pageURLs.orderSuccess,
        component: 'OrderSuccess',
      },
      {
        path: pageURLs.contractForm,
        component: 'ContractForm',
        formData: UIConfig['ContractForm'],
      },
      {
        path: pageURLs.verifyPhone,
        component: 'VerifyOTP',
        formData: UIConfig['VerifyPhone'],
      },
      {
        path: pageURLs.verifyEmail,
        component: 'VerifyOTP',
        formData: UIConfig['VerifyEmail'],
      },
      {
        path: pageURLs.scheduleVisit,
        component: 'ScheduleVisit',
        formData: UIConfig['ScheduleVisit'],
      },
      {
        path: pageURLs.idScanFront,
        component: 'IDscanning',
        formData: UIConfig['IDScanningFront'],
      },
      {
        path: pageURLs.idScanRear,
        component: 'IDscanning',
        formData: UIConfig['IDScanningRear'],
      },
      {
        path: pageURLs.idReview,
        component: 'IDreview',
        formData: UIConfig['IDreview'],
      },
      {
        path: pageURLs.documentUpload,
        component: 'DocumentUpload',
        formData: UIConfig['DocumentUpload'],
      },
      {
        path: pageURLs.confirmAdditionalClauses,
        component: 'ConfirmAdditionalClauses',
        formData: UIConfig['ConfirmAdditionalClauses'],
      },
      {
        path: pageURLs.contractSignatureInfo,
        component: 'ContractSignatureInfo',
        formData: UIConfig['ContractSignatureInfo'],
      },
      {
        path: pageURLs.signatureClient,
        component: 'Signature',
        formData: UIConfig['SignatureClient'],
      },
      {
        path: pageURLs.termsConditions,
        component: 'TermsAndConditions',
        formData: UIConfig['TermsAndConditions'],
      },
      {
        path: pageURLs.vendorSignatureInfo,
        component: 'ContractSignatureInfo',
        formData: UIConfig['VendorSignatureInfo'],
      },
      {
        path: pageURLs.signatureVendor,
        component: 'Signature',
        formData: UIConfig['SignatureVendor'],
      },
      {
        path: pageURLs.orderFailed,
        component: 'OrderFailed',
      },
      {
        path: pageURLs.incomeValidation,
        component: 'IncomeValidation',
      },
    ],
  },
  CO: CO_ROUTES,
  HN: HN_ROUTES,
  GT: GT_ROUTES,
  PY: PY_ROUTES,
  CR: CR_ROUTES,
};
