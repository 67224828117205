import { clientDetails } from './clientDetails';
import { apiStatus } from './apiStatus';
import { auth } from './auth';
import { inputAddress } from './inputAddress';
import { appointmentSchedule } from './appointmentSchedule';
import { clientInformation } from './clientInformation';
import { activation } from './activation';
import { location } from './location';
import { otp } from './otp';
import { offers } from './offers';
import { salesReport } from './salesReport';
import { idDetails } from './idDetails';
import { documentUpload } from './documentUpload';
import { customerInquiry } from './customerInquiry';
import { checkCredit } from './checkCredit';

export default {
  inputAddress,
  appointmentSchedule,
  clientDetails,
  auth,
  apiStatus,
  clientInformation,
  activation,
  location,
  offers,
  otp,
  salesReport,
  idDetails,
  documentUpload,
  customerInquiry,
  checkCredit,
}