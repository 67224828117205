import { pageURL_GT, config, appColors, pageURL_NI, NI_TEXTS } from '../constants';

export const configNI = {
  VerifyPhone: {
    title: NI_TEXTS.NI_VERIFY_PHONE_TITLE,
    inputConfig: {
      name: 'otp',
      label: 'Código de verificación',
      id: 'otp',
      type: 'tel',
      variant: 'outlined',
      inputProps: { mask: config.mask['HN'].otp },
      validations: {
        minLength: 6,
      },
    },
    buttonConfigPrimary: {
      type: 'button',
      text: 'VERIFICAR',
      name: 'submit',
    },
    skipButton: {
      text: 'OMITIR',
      color: 'transparent',
      textColor: appColors.palette.teal,
      variant: 'rounded',
      marginTop: 20,
    },
    buttonConfig: {
      color: 'transparent',
      textColor: appColors.secondaryCyan,
      variant: 'rounded',
      text: 'Solicita uno nuevo',
      textTransform: true,
      style: { fontSize: '16px' },
    },
    labelConfig: {
      text: '¿No has recibido el código?',
      fontColor: appColors.textColorGrey,
      style: { marginTop: '30px' },
    },
    type: 'phone',
    otpChannel: 'SMS',
    redirectURLs: {
      prevURL: pageURL_NI.clientAdditionalInfo,
      nextURL: pageURL_NI.verifyEmail,
      home: pageURL_NI.home,
    },
  },
  VerifyEmail: {
    title: NI_TEXTS.NI_VERIFY_EMAIL_TITLE,
    inputConfig: {
      name: 'otp',
      label: 'Código de verificación',
      id: 'otp',
      type: 'tel',
      variant: 'outlined',
      inputProps: { mask: config.mask['HN'].otp },
      validations: {
        minLength: 6,
      },
    },
    verifyButton: {
      text: 'VERIFICAR',
      variant: 'rounded',
    },
    skipButton: {
      text: 'OMITIR',
      color: 'transparent',
      textColor: appColors.palette.teal,
      variant: 'rounded',
      marginTop: 20,
    },
    buttonConfig: {
      color: 'transparent',
      textColor: appColors.secondaryCyan,
      variant: 'rounded',
      text: 'Solicita uno nuevo',
      textTransform: true,
      style: { fontSize: '16px' },
    },
    labelConfig: {
      text: '¿No has recibido el código?',
      fontColor: appColors.textColorGrey,
      style: { marginTop: '30px' },
    },
    redirectURLs: {
      prevURL: pageURL_NI.verifyPhone,
      home: pageURL_NI.home,
    },
    type: 'email',
    otpChannel: 'email',
  },
  quickQueries: {
    sidebarMenu: [{
      title: NI_TEXTS.CUSTOMER_INQUIRY_INVOICE,
      id: NI_TEXTS.CUSTOMER_INQUIRY_INVOICE,
      url: pageURL_NI.customerInquiry,
      iconType: '/images/home-icon.svg',
    }, 
    {
      title: NI_TEXTS.ORDER_INQUIRY,
      id: NI_TEXTS.ORDER_INQUIRY,
      url: pageURL_NI.orderInquiry,
      iconType: '/images/Services.svg',
    }, 
    // {
    //   title: NI_TEXTS.QUERY_NIS,
    //   id: NI_TEXTS.QUERY_NIS,
    //   url: '',
    //   iconType: '/images/Document.svg',
    // },
    {
      title: NI_TEXTS.CHECK_CREDIT,
      id: NI_TEXTS.CHECK_CREDIT,
      url: pageURL_NI.checkCredit,
      iconType: '/images/Credit_card.svg',
    }]
  },
};
