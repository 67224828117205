import fetchApi from '../../services/fetch';
import { niAPI } from '../../constants/api';
import commonSegmentCalls from 'utils/segmentTracker/NI/commonSegmentCalls';

const initialState = {
  data: {},
};

export const documentUpload = {
  state: { ...initialState },
  reducers: {
    updateDocumentDetails(state, payload) {
      return { ...state, data: { ...state.data, ...payload } };
    },
    clearDocumetDetails() {
      return { ...initialState };
    },
  },
  effects: (dispatch) => {
    return {
      uploadDocument({ data, ...params }) {
        dispatch.apiStatus.setProcessing(true);

        return fetchApi({
          method: 'post',
          url: niAPI.upload,
          data: data,
        })
          .then((response) => {
            const {
              data: {
                Payload: { fileName, imageUrl },
              },
            } = response;
            const docDetails = {
              fileName: fileName.substring(0, fileName.lastIndexOf('/')),
              folderName: fileName,
              imageUrl,
              ...params,
            };
            this.updateDocumentDetails({ [params.documentType]: { ...docDetails } });
            dispatch.apiStatus.setProcessing(false);
            return Promise.resolve({ success: true });
          })
          .catch((error) => {
            dispatch.apiStatus.setRequestFailed({ ...error });
            commonSegmentCalls.onApiFail(error, niAPI.upload, 'Document Upload API');
            return Promise.resolve({ success: false });
          });
      },
      uploadContractDocument({ data, ...params }) {
        dispatch.apiStatus.setProcessing(true);
        return fetchApi({
          method: 'post',
          url: niAPI.uploadContract,
          data: data,
        })
          .then((response) => {
            dispatch.apiStatus.setProcessing(false);
            if (response?.data?.validated) {
              const {
                data: {
                  Payload: { fileName, imageUrl },
                  output,
                },
              } = response;
              const docDetails = {
                fileName: fileName.substring(0, fileName.lastIndexOf('/')),
                folderName: fileName,
                imageUrl,
                contractId: output?.data?.contractId,
                ...params,
              };
              this.updateDocumentDetails({ [params.documentType]: { ...docDetails } });
              return Promise.resolve({ success: true });
            }
            dispatch.apiStatus.setRequestFailed({
              message:
                response?.data?.error ||
                'El contrato debe ser un documento PDF y su primera página debe mostrar los datos del mismo.',
            });
            return Promise.resolve({ success: false });
          })
          .catch((error) => {
            dispatch.apiStatus.setRequestFailed({
              ...error,
              message: 'El contrato debe ser un documento PDF y su primera página debe mostrar los datos del mismo.',
            });
            commonSegmentCalls.onApiFail(error, niAPI.upload, 'Document Upload API');
            return Promise.resolve({ success: false });
          });
      },

      deleteDocument(params, state) {
        dispatch.apiStatus.setProcessing(true);
        return fetchApi({
          method: 'post',
          url: niAPI.removeFile,
          data: {
            folderPath: state?.documentUpload?.data?.[params.field]?.folderName,
          },
        })
          .then(() => {
            this.updateDocumentDetails({ [params.field]: null });
            dispatch.apiStatus.setProcessing(false);
            return Promise.resolve({ success: true });
          })
          .catch((error) => {
            dispatch.apiStatus.setRequestFailed({ ...error });
            commonSegmentCalls.onApiFail(error, niAPI.removeFile, 'Document Delete API');
            return Promise.resolve({ success: false });
          });
      },
    };
  },
};
