export const customerAddressParams = ({ pha_data, department, municipality, neighborhood, address }) => {
    return {
        "activateService": {
            "createBasketAndAddItemsMutation": {
                "basketCreateMutation": {
                    "included": {
                        "newInstance": {
                            "attributes": {
                                "leadStep": "customer_address",
                                "salesContext": {
                                    "salespersonCode": `${pha_data?.user}`
                                }
                            }
                        }
                    }
                }
            },
            "createCustomerMutation": {
                "individualsCreateMutation": {
                    "included": {
                        "newInstance": {
                            "included": {
                                "locations": [
                                    {
                                        "attributes": {
                                            "coStreet": address,
                                            "cityID": `${department.id}`,
                                            "city": department.name,
                                            "stateID": `${municipality.id}`,
                                            "state": municipality.name,
                                            "streetID": `${neighborhood.id}`,
                                            "street": neighborhood.name
                                        }
                                    }
                                ]
                            }
                        }
                    }
                }
            }
        }
    }
}