export const REGEX_CONSTANTS = {
    HN_ID_CARD: /(\d{4})(\d{4})(\d{5})/,
    HN_PHONE_NUMBER: /(\d{4})(\d{4})/,
    EMAIL_REGEX: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/,
    GT_ID_CARD: /(\d{4})(\d{5})(\d{4})/,
    NAME_REGEX: /^[A-Za-zñáéíóúÁÉÍÑÓÚüÜ ]*$/,
    PHONE_NUMBER: /^[0-9]*$/,
    CR_ID_CED: /(\d{1})-(\d{4})-(\d{4})/,
    CR_ID_CRT: /(\d{12})/,
    CR_ID_JUR: /(\d{1})-(\d{3})-(\d{6})/,
    CR_ID_NIT: /(\d{10})/,
}
