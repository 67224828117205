
const initialState = {
  location: null
};

export const clientDetails = {
  state: { ...initialState },
  reducers: {
    updateLocation(state, payload) {
      return { ...state, location: payload };
    }
  }
};