import { UIConfig } from 'config/UIConfig';
import { configNI } from 'config/UIConfig_NI';
import { pageURL_NI } from 'constants/URLs';

export default {
  appRoutes: [
    {
      path: '/cobertura/na',
      component: 'VerifyLocation',
    },
    {
      path: '/',
      component: 'Welcome',
    },
    {
      path: '/bienvenida',
      component: 'Landing',
    },
    {
      path: '/redirect',
      component: 'Landing',
    },
    {
      path: pageURL_NI.maintenance,
      component: 'Maintenance',
    },
  ],
  privateAppRoutes: [
    {
      path: '/cobertura/na/mapa',
      component: 'CheckServices',
      formData: UIConfig['CheckServices'],
    },
    {
      path: '/',
      component: 'HomePage',
    },
    {
      path: pageURL_NI.home,
      component: 'HomePage',
    },
    {
      path: pageURL_NI.clientInformation,
      component: 'ClientInformation',
    },
    {
      path: pageURL_NI.inputAddress,
      component: 'InputAddress',
    },
    {
      path: pageURL_NI.existingServices,
      component: 'ExistingServices',
    },
    {
      path: pageURL_NI.debt,
      component: 'HasDebt',
    },
    {
      path: pageURL_NI.appointmentSchedule,
      component: 'AppointmentSchedule',
    },
    {
      path: pageURL_NI.successfulTransaction,
      component: 'SuccessfulTransaction',
    },
    {
      path: pageURL_NI.clientAdditionalInfo,
      component: 'ClientAdditionalInfo',
    },
    {
      path: pageURL_NI.verifyPhone,
      component: 'VerifyOTP',
      formData: { type: 'phone' },
    },
    {
      path: pageURL_NI.verifyEmail,
      component: 'VerifyOTP',
      formData: { type: 'email' },
    },
    {
      path: pageURL_NI.idScanFront,
      component: 'IDFront',
    },
    {
      path: pageURL_NI.idScanRear,
      component: 'IDRear',
    },
    {
      path: pageURL_NI.idReview,
      component: 'IDReview',
    },
    {
      path: pageURL_NI.map,
      component: 'LocationService',
    },
    {
      path: pageURL_NI.offerNotAvailable,
      component: 'NoOffer',
    },
    {
      path: pageURL_NI.products,
      component: 'ProductSelection',
    },
    {
      path: pageURL_NI.addons,
      component: 'Addons',
    },
    {
      path: pageURL_NI.checkout,
      component: 'Checkout',
    },
    {
      path: pageURL_NI.documentUpload,
      component: 'DocumentUpload',
    },
    {
      path: pageURL_NI.activation,
      component: 'Activation',
    },
    {
      path: pageURL_NI.checkoutSuccess,
      component: 'CheckoutSuccess',
    },
    {
      path: pageURL_NI.salesReport,
      component: 'SalesReport',
    },
    {
      path: pageURL_NI.quickQueries,
      component: 'QuickQueries',
      formData: configNI['quickQueries'],
    },
    {
      path: pageURL_NI.customerInquiry,
      component: 'CustomerInquiry',
    },
    {
      path: pageURL_NI.customerInquiryList,
      component: 'CustomerInquiry',
    },
    {
      path: pageURL_NI.orderInquiry,
      component: 'OrderInquiry',
    },
    {
      path: pageURL_NI.customerInquiryError,
      component: 'CustomerInquiry',
    },
    {
      path: pageURL_NI.checkCredit,
      component: 'CheckCredit',
    },
    {
      path: pageURL_NI.checkCreditInfo,
      component: 'CheckCredit',
    },
    {
      path: pageURL_NI.checkCreditInvalidId,
      component: 'CheckCredit',
    },
    {
      path: pageURL_NI.checkCreditNotFound,
      component: 'CheckCredit',
    },
  ],
};
