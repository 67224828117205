import { customerAddressParams } from './customerAddress';
import { clientAdditionalInformation } from './clientAdditionalInformation';
import { updateOfferParams } from './updateOffer';
import { customerLocationParams } from './customerLocation';
import { customerDocumentsParams } from './customerDocument';
import { customerAditionalDocParams } from './customerAditionalDocParams';

export const getUpdateParamsFunc = (type) => {
    switch(type){
        case 'customerAddress':
            return customerAddressParams;
        case 'clientAdditionalInformation':
            return clientAdditionalInformation;
        case 'updateOffer':
            return updateOfferParams;
        case 'customerLocation':
            return customerLocationParams;
        case 'customerDocuments':
            return customerDocumentsParams;
        case 'customerAditionalDocParams':
            return customerAditionalDocParams;
        default:
            return customerAddressParams;
    }
}