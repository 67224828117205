const development = {
  apiURL: 'https://ha-dev.tigo.com.ni',
  netCodeURL: 'https://test.id.tigo.com',
  redirectURL: `https://ha-dev.tigo.com.ni/redirect`,
  codeVerifier: process.env.REACT_APP_NI_CODE_VERIFIER,
  clientId: process.env.REACT_APP_NI_CLIENT_ID,
};

const staging = {
  apiURL: `https://ha-stg.tigo.com.ni`,
  netCodeURL: 'https://test.id.tigo.com',
  redirectURL: `https://ha-stg.tigo.com.ni/redirect`,
  codeVerifier: process.env.REACT_APP_NI_CODE_VERIFIER,
  clientId: process.env.REACT_APP_NI_CLIENT_ID,
};

const production = {
  apiURL: `https://ha.tigo.com.ni`,
  netCodeURL: 'https://id.tigo.com',
  redirectURL: `https://ha.tigo.com.ni/redirect`,
  codeVerifier: process.env.REACT_APP_NI_CODE_VERIFIER,
  clientId: process.env.REACT_APP_NI_CLIENT_ID,
};

const preproduction = {
  apiURL: `https://ha-uat.tigo.com.ni`,
  netCodeURL: 'https://id.tigo.com',
  redirectURL: 'https://ha-uat.tigo.com.ni/redirect',
  codeVerifier: process.env.REACT_APP_NI_CODE_VERIFIER,
  clientId: process.env.REACT_APP_NI_CLIENT_ID,
};

const getCurrentEnv = () => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'development':
      return development;
    case 'staging':
      return staging;
    case 'production':
      return production;
    case 'preproduction':
      return preproduction;
    default:
      return development;
  }
};

const env = getCurrentEnv();

export const appConfig = {
  ...env,
};
