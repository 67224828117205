import appboy from '@braze/web-sdk';

const enabledCountries = ['CR'];

const initializeBraze = () => {
    const key = process.env.REACT_APP_BRAZE_KEY;
    const url = process.env.REACT_APP_BRAZE_URL;
    if(key && url && enabledCountries.includes(process.env.REACT_APP_COUNTRY)){
        appboy.initialize(key, { 
            baseUrl: url,
            enableLogging: true,
            manageServiceWorkerExternally: true
        });
        registerInAppMessages();
        registerPushMessages();
        appboy.openSession();
    }
}

const setUserId = (userId) => {
    if(userId) appboy.changeUser(userId);
}

const showContentCards = () => {  
    appboy.display.showContentCards();
}

const hideContentCards = () => {
    appboy.display.hideContentCards();
}

const getUnViewedCardCount = () => {
    return appboy.getCachedContentCards().getUnviewedCardCount();
}

const registerInAppMessages = () => {
    appboy.display.automaticallyShowNewInAppMessages();
}

const registerPushMessages = () => {
    if(appboy.isPushSupported()) appboy.registerAppboyPushMessages();
}

export const brazeService = {
    enabledCountries,
    initializeBraze,
    setUserId,
    showContentCards,
    hideContentCards,
    getUnViewedCardCount
}