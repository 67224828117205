export const updateOfferParams = ({ pha_data, depositPrice, installationPrice, prodId, selectedAddons, quantity, currency }) => {
    const products = [{prodId, quantity: '1'}];
    selectedAddons.forEach(({prodId}) => {
        if(quantity?.[prodId] !== 0){
            products.push({prodId, quantity: quantity?.[prodId] ? `${quantity?.[prodId]}` :  '1' })
        }
    })
    const prodArray = products.map(({prodId, quantity}) => (
        {
            "included": {
                "newInstance": {
                    "included": {
                        "basketEntity": {
                            "basketProducts": {
                                "relationships": {
                                    "productOfferingId": `${prodId}`
                                }
                            }
                        }
                    },
                    "attributes": {
                        "quantity": quantity
                    }
                }
            }
        }
    ))
    return {
        "activateService": {
            "createBasketAndAddItemsMutation": {
                "basketCreateMutation": {
                    "included": {
                        "newInstance": {
                            "attributes": {
                                "leadStep": "customer_offers",
                                "salesContext": {
                                    "salespersonCode": `${pha_data?.user}`,
                                    "deposit": Number(depositPrice||0).toFixed(0),
                                    "cost": {
                                        "taxIncludedAmount": installationPrice, 
                                        "currency": currency
                                    }
                                }
                            }
                        }
                    }
                },
                "addBasketItemsMutation": prodArray
            }
        }
    }
}