import { getNiCountryId } from 'utils/countryList/ni';

export const customerAditionalDocParams = ({ pha_data, ...data }) => {
  return {
    activateService: {
      createBasketAndAddItemsMutation: {
        basketCreateMutation: {
          included: {
            newInstance: {
              attributes: {
                leadStep: 'customer_aditional_information',
                salesContext: {
                  salespersonCode: `${pha_data?.user}`,
                },
              },
            },
          },
        },
      },
      createCustomerMutation: {
        individualsCreateMutation: {
          included: {
            newInstance: {
              attributes: {
                gender: data.gender,
                nationality: `${getNiCountryId(data.issuingCountry)}`,
                dateOfBirth:
                  data.documentType !== 'RUC'
                    ? data?.cliDob?.format('YYYY-MM-DD')
                    : data?.issuedDate.format('YYYY-MM-DD'),
                characteristics: [
                  { key: 'CH_typeid', value: data.characteristics?.value }
                ],
              },
            },
          },
        },
      },
    },
  };
};
