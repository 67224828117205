const initialState = {
  location: [],
  permissionError: false,
};

export const location = {
  state: { ...initialState },
  reducers: {
    updateLocation(state, payload) {
      return {
        ...state,
        location: payload,
      };
    },
    effects: (dispatch) => {
      return {};
    },
  },
};
