import { pageURL_CR } from 'constants/URLs';

export default {
  appRoutes: [
    {
      path: pageURL_CR.clientInformation,
      component: 'ClientInformation',
    },
    {
      path: pageURL_CR.leadToCall,
      component: 'LeadCall',
    },
    {
      path: pageURL_CR.leadCallConfirmation,
      component: 'LeadCallConfirmation'
    },
    {
      path: `${pageURL_CR.verifyPhone}/:id?/:uid`,
      component: 'VerifyPhone',
    },
  ],
  privateAppRoutes: [
    {
      path: pageURL_CR.confirmOrder,
      component: 'ConfirmOrder',
    },
    {
      path: pageURL_CR.contractSignatureInfo,
      component: 'ContractSignatureInfo',
    },
    {
      path: pageURL_CR.signatureClient,
      component: 'Signature',
    },
    {
      path: pageURL_CR.existingClient,
      component: 'ExistingClient',
    },
    {
      path: pageURL_CR.existingDebt,
      component: 'ExistingDebt',
    },
    {
      path: pageURL_CR.invalidClient,
      component: 'InvalidClient',
    },
    {
      path: pageURL_CR.noCoverage,
      component: 'NoCoverage',
    },
    {
      path: pageURL_CR.verifyPhone,
      component: 'VerifyPhone',
    },
    {
      path: pageURL_CR.verifyEmail,
      component: 'VerifyEmail',
    },{
      path  : pageURL_CR.inputMap,
      component : 'InputMap'
    },
    {
      path: pageURL_CR.offerSelection,
      component: 'OfferSelection',
    },
    {
      path: pageURL_CR.appointmentSchedule,
      component: 'AppointmentSchedule'
    },
    {
      path  : pageURL_CR.clientIdentification,
      component: 'ClientIdentification'
    },
    {
      path: pageURL_CR.idScanFront,
      component: 'IDFront',
    },
    {
      path: pageURL_CR.inputAddress,
      component: 'InputAddress',
    },
    {
      path: pageURL_CR.paymentRedirect,
      component: 'PaymentRedirect',
    },
    {
      path: pageURL_CR.paymentFailed,
      component: 'PaymentFailed',
    },
    {
      path  : pageURL_CR.orderSuccess,
      component : 'OrderSuccess'
    }
  ],
};
