import { appColors, config, texts } from '../constants';
import { countriesListByName } from '../utils/utils';
import { pageURLs } from '../constants/URLs';

export const UIConfig = {
  Registration: {
    title: 'Regístrate',
    formFields: [
      {
        name: 'name',
        label: 'Nombre',
        id: 'cliFirstName',
        type: 'text',
        variant: 'outlined',
        inputType: 'text',
      },
      {
        name: 'surname',
        label: 'Apellido',
        id: 'cliLastName',
        type: 'text',
        inputType: 'text',
        variant: 'outlined',
      },
      {
        name: 'email',
        label: 'Correo electrónico',
        id: 'cliEmail',
        type: 'text',
        inputType: 'tel',
        variant: 'outlined',
      },
      {
        name: 'telephone',
        label: 'Número celular',
        id: 'cliTelephone',
        type: 'text',
        inputType: 'email',
        variant: 'outlined',
        inputProps: {
          maxLength: 8,
        },
      },
    ],
    formButtons: [
      { type: 'cancel', text: 'cancelar' },
      { type: 'submit', text: 'confirmar' },
    ],
    redirectURLs: { prevURL: pageURLs.idReview, nextURL: pageURLs.verifyPhone },
  },
  VerifyPhone: {
    title: 'Te hemos enviado un código de verificación al ',
    formFields: [
      {
        name: 'verify_otp',
        label: 'Código de verificación',
        id: 'verify_otp',
        type: 'text',
        variant: 'outlined',
        inputProps: {
          maxLength: 6,
        },
      },
    ],
    formButtons: [
      { type: 'button', text: 'OMITIR', name: 'cancel' },
      { type: 'button', text: 'VERIFICAR', name: 'submit' },
    ],
    footerFields: [
      {
        text: '¿No has recibido el código?',
        type: 'label',
        color: 'textColorGrey',
      },
      { type: 'button', text: 'Solicitá uno nuevo', name: 'resend' },
    ],
    redirectURLs: {
      prevURL: pageURLs.contractForm,
      nextURL: pageURLs.verifyEmail,
      verifiedURL: pageURLs.termsConditions,
      newData: pageURLs.contractForm,
    },
    verifyFunc: 'verifyPhone',
    verificationObj: 'phoneVerified',
    displayObj: 'cliTelephone',
  },
  VerifyEmail: {
    title: 'Te hemos enviado un código de verificación al ',
    formFields: [
      {
        name: 'verify_otp',
        label: 'Código de verificación',
        id: 'verify_otp',
        type: 'text',
        variant: 'outlined',
      },
    ],
    formButtons: [
      { type: 'button', text: 'OMITIR', name: 'cancel' },
      { type: 'button', text: 'VERIFICAR', name: 'submit' },
    ],
    footerFields: [
      {
        text: '¿No has recibido el código?',
        type: 'label',
        color: 'textColorGrey',
      },
      { type: 'button', text: 'Solicitá uno nuevo', name: 'resend' },
    ],
    redirectURLs: {
      prevURL: pageURLs.verifyPhone,
      nextURL: pageURLs.termsConditions,
      verifiedURL: pageURLs.termsConditions,
      newData: pageURLs.contractForm,
    },
    verifyFunc: 'verifyEmail',
    verificationObj: 'emailVerified',
    displayObj: 'cliEmail',
  },
  ScheduleVisit: {
    title: 'Seleccioná la fecha y hora para tu instalación',
    formFields: [
      {
        name: 'direction',
        id: 'direction',
        type: 'label',
        text: 'Dirección a instalar:',
      },
      {
        type: 'radio',
        options: [
          { value: 'AM', text: 'AM' },
          { value: 'PM', text: 'PM' },
        ],
        name: 'time',
        id: 'workingDate',
      },
      {
        name: 'date',
        id: 'cliInstallationDate',
        type: 'date',
        variant: 'outlined',
        label: 'Seleccionar fecha',
      },
      {
        name: 'telephone',
        label: 'Teléfono para confirmar la instalación',
        id: 'cliTelephone',
        type: 'text',
        variant: 'outlined',
        inputProps: { mask: config.mask['HN'].phoneNumber },
        mask: true,
      },
      {
        name: 'email',
        label: 'Correo electrónico',
        id: 'cliEmail',
        type: 'text',
        variant: 'outlined',
      },
      {
        name: 'comments',
        label: 'Mensaje para el instalador',
        id: 'actComments',
        type: 'textarea',
        variant: 'outlined',
        multiline: true,
        rows: 5,
      },
      {
        type: 'button',
        text: 'confirmar',
        name: 'submit',
      },
    ],
    redirectURLs: {
      prevURL: pageURLs.documentUpload,
      nextURL: pageURLs.confirmOrder,
      redirectURL: '/',
    },
  },
  IDScanningFront: {
    title: 'Capturá el documento de identidad',
    formFields: [
      {
        type: 'label',
        text: 'A continuación tomaremos una foto de ambas caras del DUI.',
      },
      {
        type: 'cameraInstruction',
      },
      {
        type: 'checkbox',
        text: 'Quiero cargar una foto de mi teléfono',
      },
      {
        type: 'scanner',
        scannerConfig: {
          btnText: 'TOMAR FOTO FRONTAL',
          fileName: 'ID_front_side',
          field: 'idFront',
          getImageFunc: 'uploadFront',
          compressionConfig: {
            maxSizeMB: 0.5,
            useWebWorker: true,
          },
          redirectURL: pageURLs.idScanRear,
          documentType: 'DUI',
        },
      },
      // {
      //   type: 'scanner',
      //   scannerConfig: {
      //     btnText: 'QUIERO USAR OTRO DOCUMENTO',
      //     fileName: 'ID_front_side',
      //     field: 'idFront',
      //     getImageFunc: 'uploadFront',
      //     compressionConfig: {
      //       maxSizeMB: 0.5,
      //       useWebWorker: true,
      //     },
      //     transparentButton: true,
      //     documentType: 'other',
      //     redirectURL: pageURLs.idReview,
      //   },
      // },
      {
        type: 'skip',
        btnText: texts.SKIP,
        redirectURL: pageURLs.idReview,
      },
    ],
    redirectURLs: {
      prevURL: pageURLs.incomeValidation,
      nextURL: pageURLs.idScanRear,
      redirectURL: '/',
    },
    idObject: 'frontId',
  },
  IDScanningRear: {
    title: 'Foto Posterior',
    formFields: [
      {
        type: 'label',
        text: 'Ahora tomaremos la foto del reverso del ID.',
      },
      {
        type: 'cameraInstruction',
        instructions: [{ src: '/images/FotoPosterior(Icon 1).svg', description: '' }],
      },
      {
        type: 'label',
        text: 'Gira la cámara para un mejor ángulo.',
      },
      {
        type: 'cameraInstruction',
        instructions: [{ src: '/images/FotoPosterior(Icon 2).svg', description: '' }],
      },
      {
        type: 'scanner',
        scannerConfig: {
          btnText: 'TOMA LA FOTO POSTERIOR',
          fileName: 'ID_rear_side',
          field: 'idRear',
          getImageFunc: 'getRearIdDetails',
          compressionConfig: {
            maxSizeMB: 2,
            useWebWorker: true,
          },
          redirectURL: pageURLs.idReview,
        },
      },
    ],
    redirectURLs: {
      prevURL: pageURLs.idScanFront,
      nextURL: pageURLs.idReview,
      redirectURL: '/',
    },
  },
  IDreview: {
    title: 'Confirma la información',
    formFields: [
      {
        type: 'documentThumbnail',
      },
      {
        type: 'label',
        text: 'No pudimos extraer tu información de la cédula. Por favor ingrésala manualmente.',
      },
      [
        {
          name: 'Tipo',
          label: 'Tipo',
          id: 'cliDocumentType',
          type: 'select',
          variant: 'outlined',
          optional: false,
          options: ['DUI', 'Pasaporte', 'Carnet de Residente'],
        },
        {
          name: 'cliDUINumber',
          label: 'Número de Documento',
          id: 'cliDUINumber',
          type: 'tel',
          variant: 'outlined',
        },
      ],
      {
        name: 'issuingCountry',
        label: 'Nacionalidad',
        id: 'issuingCountry',
        type: 'select',
        variant: 'outlined',
        optional: false,
        options: [...countriesListByName()],
      },
      {
        name: 'cliLastName',
        label: 'Apellidos',
        id: 'cliLastName',
        type: 'text',
        variant: 'outlined',
      },
      {
        name: 'cliFirstName',
        label: 'Nombres',
        id: 'cliFirstName',
        type: 'text',
        variant: 'outlined',
      },
      {
        name: 'cliDob',
        id: 'cliDob',
        type: 'date',
        variant: 'outlined',
        disableFuture: true,
      },
      {
        name: 'cliDocExpiry',
        id: 'cliDocExpiry',
        type: 'date',
        variant: 'outlined',
        label: 'Fecha de expiración',
        disablePast: false,
      },
      {
        name: 'cliGender',
        label: 'Género',
        id: 'cliGender',
        type: 'select',
        variant: 'outlined',
        options: ['', 'Masculino', 'Femenino'],
      },
      {
        name: 'cliProfession',
        label: 'Profesión',
        id: 'cliProfession',
        type: 'text',
        variant: 'outlined',
        inputProps: {
          maxLength: 50,
        },
      },
      {
        name: 'cliOffice',
        label: 'Lugar de trabajo',
        id: 'cliOffice',
        type: 'text',
        variant: 'outlined',
        inputProps: {
          maxLength: 50,
        },
      },
      {
        type: 'button',
        text: 'confirmar',
        name: 'submit',
      },
    ],
    redirectURLs: {
      prevURL: pageURLs.idScanRear,
      prevURLForeignUser: pageURLs.idScanFront,
      nextURL: pageURLs.contractForm,
      redirectURL: pageURLs.idScanFront,
    },
  },
  DocumentUpload: {
    title: 'Cargá o tomá fotos de los siguientes documentos que verifiquen tu domicilio e ingresos.',
    formFields: [
      {
        type: 'upload',
        scannerConfig: {
          btnText: 'Recibo de agua o electricidad',
          fileName: 'Recibo_energia',
          field: 'reciboEnergia',
          getImageFunc: 'uploadDocument',
          compressionConfig: {
            maxSizeMB: 0.5,
            useWebWorker: true,
          },
        },
      },
      {
        type: 'upload',
        onlyForIncomeProof: true,
        scannerConfig: {
          btnText: 'Comprobante de ingresos',
          fileName: 'salary_proof',
          field: 'salary_proof',
          getImageFunc: 'uploadDocument',
          customDoc: true,
          compressionConfig: {
            maxSizeMB: 0.5,
            useWebWorker: true,
          },
        },
      },
    ],
    redirectURLs: {
      prevURL: pageURLs.signatureClient,
      nextURL: pageURLs.scheduleVisit,
      redirectURL: '/',
    },
    customStyles: {
      titleStyle: {
        fontSize: '18px',
        margin: '0px 7px',
        lineHeight: '24px',
        color: '#00377D',
        marginTop: '3px',
        textAlign: 'center',
        wordBreak: 'break-word',
      },
    },
  },
  ContractForm: {
    title: 'Completá estos pasos para crear la Orden de Trabajo (ODT)',
    formFields: [
      {
        name: 'cliLastName',
        label: 'Apellidos',
        id: 'cliLastName',
        type: 'text',
        variant: 'outlined',
        inputType: 'text',
        disabled: true,
      },
      {
        name: 'cliFirstName',
        label: 'Nombres',
        id: 'cliFirstName',
        type: 'text',
        variant: 'outlined',
        inputType: 'text',
        disabled: true,
      },
      {
        name: 'cliEmail',
        label: 'Correo electrónico',
        id: 'cliEmail',
        type: 'text',
        variant: 'outlined',
        inputType: 'email',
      },
      {
        name: 'telephone',
        placeholder: 'Número celular',
        id: 'cliTelephone',
        type: 'text',
        inputType: 'tel',
        variant: 'outlined',
        inputProps: { mask: config.mask['HN'].phoneNumber },
        mask: true,
      },
      {
        name: 'telephone',
        label: 'Número secundario',
        id: 'cliTelephoneSecondary',
        type: 'text',
        inputType: 'tel',
        variant: 'outlined',
        inputProps: { mask: config.mask['HN'].phoneNumber },
        mask: true,
      },
      {
        title: 'Referencia personal #1',
        type: 'inputList',
        options: [
          {
            name: 'name',
            label: 'Nombre completo',
            id: 'refNameA',
            type: 'text',
            inputType: 'text',
            variant: 'outlined',
            helperText: 'Campo requerido',
            inputProps: {
              maxLength: 19,
            },
          },
          {
            name: 'telephone',
            label: 'Teléfono',
            id: 'refTelephoneA',
            type: 'text',
            variant: 'outlined',
            inputType: 'tel',
            helperText: 'Campo requerido',
            inputProps: { mask: config.mask['HN'].phoneNumber },
            mask: true,
          },
        ],
      },
      {
        title: 'Referencia personal #2',
        type: 'inputList',
        options: [
          {
            name: 'name',
            label: 'Nombre completo',
            id: 'refNameB',
            type: 'text',
            inputType: 'text',
            variant: 'outlined',
            helperText: 'Campo requerido',
            inputProps: {
              maxLength: 19,
            },
          },
          {
            name: 'telephone',
            label: 'Teléfono',
            id: 'refTelephoneB',
            type: 'text',
            inputType: 'tel',
            variant: 'outlined',
            helperText: 'Campo requerido',
            inputProps: { mask: config.mask['HN'].phoneNumber },
            mask: true,
          },
        ],
      },
      {
        text: 'confirmar',
        type: 'button',
        name: 'submit',
      },
    ],
    redirectURLs: {
      prevURL: pageURLs.idReview,
      nextURL: pageURLs.verifyPhone,
      redirectURL: '/',
    },
  },
  HomePage: {
    formFields: [],
    sidebarMenu: [
      {
        title: 'Nuevo Cliente',
        url: pageURLs.clientInfo,
        iconType: 'New User',
      },
      {
        title: 'Reporte de ventas',
        url: pageURLs.salesReport,
        iconType: 'Sales Report',
      },
      {
        title: 'Cerrar sesión',
        url: pageURLs.logout,
        iconType: 'Logout',
      },
    ],
  },
  ProductCheckout: {
    formData: [],
    mockData: {
      months: ['0 meses', '3 meses', '6 meses', '12 meses', '18 meses'],
    },
    redirectURLs: {
      prevURL: pageURLs.confirmPackage,
      nextURL: pageURLs.incomeValidation,
      redirectURL: pageURLs.mainMenu,
      jumpToURL: pageURLs.confirmOrder,
    },
  },
  MainMenu: {
    formData: [],
    sidebarMenu: [
      {
        title: 'Nuevo Cliente',
        url: pageURLs.clientInfo,
      },
      {
        title: 'Reporte de ventas',
        url: pageURLs.salesReport,
      },
      {
        title: 'Cerrar sesión',
        url: pageURLs.logout,
      },
    ],
    redirectURLs: { nextURL: pageURLs.confirmPackage, prevURL: pageURLs.inputAddress, redirectURL: '/' },
  },
  ConfirmPackage: {
    title: 'Resumen',
    formButtons: [{ type: 'submit', text: 'continuar' }],
    redirectURLs: { prevURL: pageURLs.mainMenu, nextURL: pageURLs.checkout, redirectURL: pageURLs.mainMenu },
  },
  InputAddress: {
    title: 'Encontrá una dirección',
    sidebarMenu: [
      {
        title: 'Nuevo Cliente',
        url: pageURLs.clientInfo,
      },
      {
        title: 'Reporte de ventas',
        url: pageURLs.salesReport,
      },
      {
        title: 'Cerrar sesión',
        url: pageURLs.logout,
      },
    ],
    formFields: [
      {
        type: 'autocompleteList',
        id: 'location',
        inputList: [
          {
            name: 'Departmento',
            label: 'Departamento',
            id: 'department',
            dependencies: ['city', 'suburb'],
          },
          {
            name: 'Ciudad',
            label: 'Ciudad',
            id: 'city',
            dependencies: ['suburb'],
            dependentOn: ['department'],
          },
          {
            name: 'Colonia',
            label: 'Colonia',
            id: 'suburb',
            dependentOn: ['department', 'city'],
          },
        ],
      },
      {
        name: 'Calle',
        label: 'Calle',
        id: 'street',
        type: 'text',
        inputProps: {
          maxLength: 30,
        },
      },
      {
        name: 'Numero de Casa',
        label: 'Número de Casa',
        id: 'house',
        type: 'text',
        inputProps: {
          maxLength: 30,
        },
      },
      {
        name: 'Informacion complementaria',
        label: 'Información complementaria',
        id: 'actAddressRef',
        type: 'text',
        inputProps: {
          maxLength: 30,
        },
      },
      {
        type: 'checkbox',
        label: 'Me encuentro en la casa del cliente',
      },
      {
        text: 'confirmar',
        type: 'button',
      },
    ],
    redirectURLs: {
      prevURL: pageURLs.clientInfo,
    },
  },
  ConfirmAdditionalClauses: {
    title: 'Autorizaciones y firma',
    subTitle: 'Para continuar, debés seleccionar todas las casillas',
    formFields: [
      {
        title: 'Autorizo la consulta de mi información personal y crediticia',
        content:
          'AUTORIZO a TELEMÓVIL EL SALVADOR, S.A. DE C.V., en adelante TELEMÓVIL para que consulte y verifique mi información crediticia e historial de crédito, con Agencias de información de datos. Esta autorización ha sido libremente discutida. Así me expreso y firmo el presente documento.',
        type: 'authorization',
      },
      {
        title: 'Autorización para compartir información',
        content:
          'AUTORIZO a TELEMÓVIL EL SALVADOR, S.A. DE C.V., en adelante TELEMÓVIL compartir mi información, personal, crediticia e historial de crédito con la agencia de información de dato Equifax Centroamérica S.A de C.V. Declaro que la información proporcionada a TELEMÓVIL por este y cualquier medio digital o escrito es veraz y completa. Esta autorización ha sido libremente discutida. Así me expreso y firmo el presente documento.',
        type: 'authorization',
      },
      {
        title: 'Cláusula medios alternos de solución de controversias',
        content:
          'DECLARO que en caso de conflicto posterior con TELEMÓVIL, previo a cualquier procedimiento administrativo o judicial, agotaré los medios alternos de solución de controversias disponibles.',
        type: 'authorization',
      },
      {
        title: 'Consulta de información crediticia',
        content:
          'Se validará el score crediticio del cliente. Necesitaremos contar con el aval del cliente con el nuevo proceso de firma digita, luego de presionar CONTINUAR prestale tu teléfono al cliente que deberáfirmar en este punto las autorizaciones para consultar el buró crediticio y los medios alternos de solución de controversia.',
        type: 'authorization',
      },
    ],
    redirectURLs: { prevURL: pageURLs.termsConditions, nextURL: pageURLs.contractSignatureInfo, redirectURL: '/' },
  },
  ContractSignatureInfo: {
    title: 'Firma de autorizaciones',
    formFields: [
      {
        type: 'cameraInstruction',
        instructions: [{ src: '/images/contract-sign.svg' }],
      },
      {
        type: 'label',
        text: 'Gira tu celular',
        labelSize: 'large',
        fontColor: appColors.textColorBlue,
      },
      {
        type: 'label',
        text: 'Usa tu dedo para firmar sobre la línea.',
        fontColor: appColors.textColorGrey,
      },
      {
        type: 'button',
        variant: 'rounded',
        text: 'comenzar',
      },
    ],
    redirectURLs: { prevURL: pageURLs.confirmAdditionalClauses, nextURL: pageURLs.signatureClient, redirectURL: '/' },
  },
  SignatureClient: {
    activationKeys: ['buro-consulta', 'buro-compartir', 'medios-solucion', 'aceptacion-cliente'],
    redirectURLs: { nextURL: pageURLs.vendorSignatureInfo },
    displayNameFnc: 'getClientName',
  },
  TermsAndConditions: {
    title: 'Acuerdo de Servicios',
    redirectURLs: {
      nextURL: pageURLs.confirmAdditionalClauses,
      prevURL: pageURLs.verifyEmail,
      redirectURL: '/',
      verifiedURL: pageURLs.verifyPhone,
      newData: pageURLs.contractForm,
    },
  },
  VendorSignatureInfo: {
    title: 'Firma del vendedor',
    formFields: [
      {
        type: 'cameraInstruction',
        instructions: [{ src: '/images/contract-sign-seller.svg' }],
      },
      {
        type: 'label',
        text: 'Aceptación de términos y condiciones de Tigo',
        labelSize: 'large',
        fontColor: appColors.textColorBlue,
      },
      {
        type: 'label',
        text: 'Como vendedor, debes realizar esta firma para confirmar la aceptación del contrato que hacemos con nuestros clientes.',
        fontColor: appColors.textColorGrey,
      },
      {
        type: 'button',
        variant: 'rounded',
        text: 'comenzar',
      },
    ],
    redirectURLs: { prevURL: pageURLs.signatureClient, nextURL: pageURLs.signatureVendor, redirectURL: '/' },
  },
  SignatureVendor: {
    activationKeys: ['aceptacion-vendedor'],
    redirectURLs: { nextURL: pageURLs.documentUpload },
    displayNameFnc: 'getVendorName',
  },
  SalesReport: {
    sidebarMenu: [
      {
        title: 'Nuevo Cliente',
        url: pageURLs.clientInfo,
      },
      {
        title: 'Reporte de ventas',
        url: pageURLs.salesReport,
      },
      {
        title: 'Cerrar sesión',
        url: pageURLs.logout,
      },
    ],
  },
  CheckServices: {
    title: 'Verificá si la ubicación es la correcta.',
    subHeading: 'Podés ajustarla tocando el mapa.',
    marker: {
      tigo: 'Tigo Hogar ',
      available: 'está',
      unavailable: 'no está',
      here: 'disponible aquí.',
      src: '/images/Pin_icn.svg',
      zoom: 12.5,
      offset: 25,
    },
    formButtons: [
      {
        type: 'button',
        text_failed: 'VER SERVICIOS MÓVILES',
        text_success: 'SOLICITÁ EL SERVICIO AHORA',
        name: 'submit',
      },
    ],
    redirectURLs: {
      prevURL: '/cobertura/na',
      externalWithCoverage: 'https://www.tigo.com.ni/planes?utm_source=webTigo&utm_campaign=ha.tigo.com.ni',
      externalWithoutCoverage: 'https://www.tigo.com.ni/pospago?utm_source=webTigo&utm_campaign=ha.tigo.com.ni',
    },
  },
  SalesDetails: {
    editInfo: [
      {
        text: texts.EDIT_DETAILS,
        url: pageURLs.idScanFront,
      },
      {
        text: texts.EDIT_DIRECTION,
        url: pageURLs.inputAddress,
      },
      {
        text: texts.EDIT_PRODUCTS,
        url: pageURLs.mainMenu,
      },
      {
        text: texts.EDIT_INFORMATION,
        url: pageURLs.contractForm,
      },
      {
        text: texts.EDIT_REFERENCES,
        url: pageURLs.contractForm,
      },
      {
        text: texts.EDIT_SIGNATURE,
        url: pageURLs.termsConditions,
        hideElem: true,
      },
      {
        text: texts.EDIT_DOCUMENT,
        url: pageURLs.documentUpload,
      },
      {
        text: texts.EDIT_INSTALLATION,
        url: pageURLs.scheduleVisit,
      },
    ],
  },
};

export const fieldEditStatus = {
  idReview: {
    cliDUINumber: false,
    cliLastName: false,
    cliFirstName: false,
    cliDob: false,
    cliDocExpiry: false,
    cliGender: false,
    cliDocumentType: false,
  },
};
