
import fetchApi from '../../services/fetch';
import { niAPI } from '../../constants/api';
import commonSegmentCalls from 'utils/segmentTracker/NI/commonSegmentCalls';

const initialState = {
    id: null,
    timeSlots: null,
    installationDate: null,
    selectedDate: null,
    response: null,
    submitted: false 
};

export const appointmentSchedule = {
    state: { ...initialState },
    reducers: {
        updateAppointmentDetails(state, payload) {
            return { ...state, ...payload };
        }
    },
    effects: dispatch => {
        return {
            async getTimeSlots(params, state) {
                dispatch.apiStatus.setProcessing(true);
                const api = niAPI.getInstallationDates.replace(':id', state?.activation?.response?.response?.customerId);
                return fetchApi({
                    method: 'get',
                    url: api,
                    params
                })
                    .then(response => {
                        dispatch.appointmentSchedule.updateAppointmentDetails({
                            id: `${response.data?.instalation?.id}`,
                            timeSlots: response.data?.instalation?.timeSlots,
                            selectedDate: response.data?.instalation?.timeSlots?.[0]?.date,
                            response: response.data
                        });
                        dispatch.apiStatus.setProcessing(false);
                        return response
                    })
                    .catch(error => {
                        dispatch.apiStatus.setProcessing(false);
                        commonSegmentCalls.onApiFail(error, api, 'Get Time Slots API');
                        return error
                    });
            },
            async scheduleInstallation(params, state) {
                dispatch.apiStatus.setProcessing(true);
                const api = niAPI.getInstallationDates.replace(':id', state?.activation?.response?.response?.customerId);
                return fetchApi({
                    method: 'post',
                    url: api,
                    data: {
                        installation: {
                            type: `${state?.appointmentSchedule?.id}`
                        },
                        timeSlots: {
                            date: state?.appointmentSchedule?.selectedDate,
                            id: `${state?.appointmentSchedule?.installationDate}`
                        }
                    }
                })
                    .then(response => {
                        dispatch.apiStatus.setProcessing(false);
                        dispatch.appointmentSchedule.updateAppointmentDetails({
                            submitted: true
                        });
                        return Promise.resolve({ success: true });
                    })
                    .catch(error => {
                        if (error.errorCode === 200) {
                            dispatch.apiStatus.setProcessing(false);
                            dispatch.appointmentSchedule.updateAppointmentDetails({
                                submitted: true
                            });
                            return Promise.resolve({ success: true });
                        } else {
                            dispatch.apiStatus.setRequestFailed({ ...error });
                            commonSegmentCalls.onApiFail(error, api, 'Schedule Visit API');
                            return Promise.resolve({ success: false });
                        }
                    });
            }
        }
    }
};